.uui-typography {
  font-family: var(--uui-font);
  color: var(--uui-text-primary);
}
.uui-typography .hero-header, .uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin: 0;
}
.uui-typography .hero-header {
  font-family: var(--uui-font);
  font-weight: 500;
  margin: 30px 0;
  font-size: 66px;
  line-height: 72px;
}
.uui-typography .promo-header, .uui-typography h1, .uui-typography h2, .uui-typography h3 {
  font-family: var(--uui-font);
  font-weight: 700;
}
.uui-typography h4, .uui-typography h5, .uui-typography h6 {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.uui-typography h1 {
  font-size: 42px;
}
.uui-typography h2 {
  font-size: 30px;
  letter-spacing: 0.1px;
}
.uui-typography h2 .promo-header {
  letter-spacing: 2px;
}
.uui-typography h3 {
  font-size: 24px;
}
.uui-typography h4 {
  font-size: 18px;
  letter-spacing: 0.1px;
}
.uui-typography h5 {
  font-size: 16px;
}
.uui-typography h6 {
  font-size: 14px;
}
.uui-typography b, .uui-typography strong {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography i, .uui-typography em {
  font-family: var(--uui-font);
  font-weight: 400;
  font-style: italic;
}
.uui-typography a {
  display: inline-flex;
  align-items: center;
  color: var(--uui-link);
  text-decoration: underline;
}
.uui-typography a:visited {
  color: var(--uui-link-visited);
}
.uui-typography a:visited:hover {
  color: var(--uui-link-visited-hover);
}
.uui-typography a:hover {
  color: var(--uui-link-hover);
}
.uui-typography code {
  padding: 0.125em 0.25em;
  color: var(--uui-text-primary);
  background-color: var(--uui-neutral-30);
  font-family: var(--uui-font-mono, monospace);
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography ol, .uui-typography ul {
  padding: 0;
}
.uui-typography li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.uui-typography pre {
  padding: 1.25em;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography img {
  max-width: 100%;
}
.uui-typography figure {
  margin: 0;
}
.uui-typography figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.uui-typography p {
  margin: 0.5em 0;
  line-height: 1.5;
}
.uui-typography .uui-critical {
  color: var(--uui-text-critical);
}
.uui-typography .uui-info {
  color: var(--uui-text-info);
}
.uui-typography .uui-success {
  color: var(--uui-text-success);
}
.uui-typography .uui-warning {
  color: var(--uui-text-warning);
}
.uui-typography .uui-highlight {
  background-color: var(--uui-warning-10);
}

.uui-typography-size-12 {
  font-size: 12px;
}

.uui-typography-size-14 {
  font-size: 14px;
}

.uui-typography-size-16 {
  font-size: 16px;
}

.root {
  --uui-modals-shadow: var(--uui-shadow-level-3);
  --uui-modals-bg: var(--uui-surface-main);
  --uui-modals-border-radius: calc(var(--uui-border-radius) * 2);
}

.modal-blocker {
  animation-duration: 0.3s;
  animation-name: animateModalBlocker;
  opacity: 1;
  transition: all 0.3s ease-out;
  background-color: var(--uui-overlay);
}
@keyframes animateModalBlocker {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal:global(.uui-modal-window) {
  background-color: var(--uui-modals-bg);
  overflow: auto;
  border-radius: var(--uui-modals-border-radius);
  max-width: 100vw;
  box-shadow: var(--uui-modals-shadow);
}
@media (max-width: 720px) {
  .modal:global(.uui-modal-window) {
    min-width: 100vw;
    border-radius: 0;
  }
}
.modal:global(.uui-modal-window) :global(.uui-shadow-top)::before {
  background: var(--uui-divider);
  height: 1px;
}
.modal:global(.uui-modal-window) :global(.uui-shadow-bottom)::after {
  background: var(--uui-divider);
  height: 1px;
}

.modal-footer {
  border-bottom-left-radius: var(--uui-modals-border-radius);
  border-bottom-right-radius: var(--uui-modals-border-radius);
}

.modal-header {
  border-top-left-radius: var(--uui-modals-border-radius);
  border-top-right-radius: var(--uui-modals-border-radius);
}