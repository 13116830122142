.root {
  --uui-dt-cell-editor-border-width: 1px;
  position: absolute;
}
.root:global(.uui-focus) {
  --uui-dt-cell-editor-border-width: 2px;
}
.root:not(:global(.uui-table-cell-selection-in-progress)) {
  pointer-events: none;
}

.copying-marker {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 7px;
  height: 7px;
  cursor: crosshair;
  pointer-events: all;
}
.copying-marker::after {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  right: 0;
  width: 12px;
  height: 12px;
}

.overlay {
  position: absolute;
  top: calc(var(--uui-dt-row-border-width) * -1);
  bottom: calc(var(--uui-dt-row-border-width) * -1);
  left: calc(var(--uui-dt-row-border-width) * -1);
  right: calc(var(--uui-dt-row-border-width) * -1);
  border-width: var(--uui-dt-cell-editor-border-width);
  border-style: solid;
  border-color: transparent;
  visibility: hidden;
}
.overlay:global(.uui-focus) {
  z-index: 2;
  visibility: visible;
  border-color: var(--uui-dt-cell-border-focus);
}
.overlay :global(.uui-copying-marker) {
  background-color: var(--uui-dt-cell-border-focus);
}
.overlay :global(.uui-copying-marker-hover) {
  background-color: var(--uui-dt-cell-border-hover);
}
:global(.uui-table-cell):hover .overlay:not(:global(.uui-table-cell-selection-in-progress)):not(:global(.uui-focus)) {
  z-index: 1;
  visibility: visible;
  border-color: var(--uui-dt-cell-border-hover);
}
:global(.uui-table-cell) .overlay:global(.uui-table-cell-selection-in-progress) {
  z-index: 3;
  visibility: visible;
}
:global(.uui-table-cell) .overlay:global(.uui-table-cell-selected) {
  z-index: 3;
  visibility: visible;
}
:global(.uui-table-cell) .overlay:global(.uui-table-cell-selected):global(.uui-table-cell-selected-top) {
  border-top-color: var(--uui-dt-cell-border-focus);
}
:global(.uui-table-cell) .overlay:global(.uui-table-cell-selected):global(.uui-table-cell-selected-right) {
  border-right-color: var(--uui-dt-cell-border-focus);
}
:global(.uui-table-cell) .overlay:global(.uui-table-cell-selected):global(.uui-table-cell-selected-bottom) {
  border-bottom-color: var(--uui-dt-cell-border-focus);
}
:global(.uui-table-cell) .overlay:global(.uui-table-cell-selected):global(.uui-table-cell-selected-left) {
  border-left-color: var(--uui-dt-cell-border-focus);
}
:global(.uui-table-cell) .overlay:global(.uui-table-cell-selected):global(.uui-table-cell-selected-top):not(:global(.uui-table-cell-selected-right)), :global(.uui-table-cell) .overlay:global(.uui-table-cell-selected):global(.uui-table-cell-selected-bottom):not(:global(.uui-table-cell-selected-right)) {
  border-right: 0;
}
:global(.uui-table-cell) .overlay:global(.uui-table-cell-selected):global(.uui-table-cell-selected-top):not(:global(.uui-table-cell-selected-left)), :global(.uui-table-cell) .overlay:global(.uui-table-cell-selected):global(.uui-table-cell-selected-bottom):not(:global(.uui-table-cell-selected-left)) {
  border-left: 0;
}
:global(.uui-table-cell) .overlay:global(.uui-table-cell-selected):global(.uui-table-cell-selected-right):not(:global(.uui-table-cell-selected-bottom)), :global(.uui-table-cell) .overlay:global(.uui-table-cell-selected):global(.uui-table-cell-selected-left):not(:global(.uui-table-cell-selected-bottom)) {
  border-bottom: 0;
}
:global(.uui-table-cell) .overlay:global(.uui-table-cell-selected):global(.uui-table-cell-selected-right):not(:global(.uui-table-cell-selected-top)), :global(.uui-table-cell) .overlay:global(.uui-table-cell-selected):global(.uui-table-cell-selected-left):not(:global(.uui-table-cell-selected-top)) {
  border-top: 0;
}