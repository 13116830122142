.container {
  display: flex;
  cursor: pointer;
  align-items: center;
}
.container:focus-within {
  outline: none;
}
.container :global(.uui-checkbox) {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
}
.container :global(.uui-checkbox) input[type=checkbox] {
  margin: 0;
  border: 0 none;
  width: 0;
  height: 0;
  appearance: none;
}
.container :global(.uui-checkbox) input[type=checkbox]:focus {
  outline: none;
}