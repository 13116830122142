.container {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 60px;
  padding-left: 18px;
  padding-right: 6px;
  background-color: transparent;
  border: 0 none;
}
.container :global(.uui-caption), .container :global(.uui-input) {
  padding-top: 21px;
  padding-bottom: 21px;
  min-width: 60px;
  line-height: 18px;
  font-size: 18px;
}
.container:global(.-clickable) {
  cursor: pointer;
}
.container:global(.-clickable):focus, .container:global(.-clickable):hover {
  background: var(--uui-main_menu-dropdown-bg);
  outline: none;
}
.container:global(.-clickable):active {
  background-color: var(--uui-main_menu-dropdown-bg);
}
.container:global(.-clickable):focus-visible {
  outline: 2px solid var(--uui-outline-focus);
  outline-offset: -2px;
  border-radius: 0;
}

.open {
  background: var(--uui-main_menu-dropdown-bg);
}