.root {
  --uui-dropspot-border: var(--uui-control-border);
  --uui-dropspot-border-dropstart: var(--uui-primary-50);
  --uui-dropspot-background-dropstart: var(--uui-primary-10);
  --uui-dropspot-icon: var(--uui-primary-50);
  border: 2px dashed var(--uui-dropspot-border);
  display: flex;
  align-items: center;
  justify-content: center;
}
.root.drop-start {
  border: 2px dashed var(--uui-dropspot-border-dropstart);
}
.root.drop-over {
  background-color: var(--uui-dropspot-background-dropstart);
}
.root .link {
  color: var(--uui-link);
}
.root .link:hover {
  color: var(--uui-link-hover);
}
.root .drop-area {
  flex-basis: 100%;
  padding: 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.root .drop-caption {
  margin-right: 6px;
}
.root .icon-blue {
  fill: var(--uui-dropspot-icon);
  margin-right: 6px;
}