.uui-typography {
  font-family: var(--uui-font);
  color: var(--uui-text-primary);
}
.uui-typography .hero-header, .uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin: 0;
}
.uui-typography .hero-header {
  font-family: var(--uui-font);
  font-weight: 500;
  margin: 30px 0;
  font-size: 66px;
  line-height: 72px;
}
.uui-typography .promo-header, .uui-typography h1, .uui-typography h2, .uui-typography h3 {
  font-family: var(--uui-font);
  font-weight: 700;
}
.uui-typography h4, .uui-typography h5, .uui-typography h6 {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.uui-typography h1 {
  font-size: 42px;
}
.uui-typography h2 {
  font-size: 30px;
  letter-spacing: 0.1px;
}
.uui-typography h2 .promo-header {
  letter-spacing: 2px;
}
.uui-typography h3 {
  font-size: 24px;
}
.uui-typography h4 {
  font-size: 18px;
  letter-spacing: 0.1px;
}
.uui-typography h5 {
  font-size: 16px;
}
.uui-typography h6 {
  font-size: 14px;
}
.uui-typography b, .uui-typography strong {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography i, .uui-typography em {
  font-family: var(--uui-font);
  font-weight: 400;
  font-style: italic;
}
.uui-typography a {
  display: inline-flex;
  align-items: center;
  color: var(--uui-link);
  text-decoration: underline;
}
.uui-typography a:visited {
  color: var(--uui-link-visited);
}
.uui-typography a:visited:hover {
  color: var(--uui-link-visited-hover);
}
.uui-typography a:hover {
  color: var(--uui-link-hover);
}
.uui-typography code {
  padding: 0.125em 0.25em;
  color: var(--uui-text-primary);
  background-color: var(--uui-neutral-30);
  font-family: var(--uui-font-mono, monospace);
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography ol, .uui-typography ul {
  padding: 0;
}
.uui-typography li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.uui-typography pre {
  padding: 1.25em;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography img {
  max-width: 100%;
}
.uui-typography figure {
  margin: 0;
}
.uui-typography figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.uui-typography p {
  margin: 0.5em 0;
  line-height: 1.5;
}
.uui-typography .uui-critical {
  color: var(--uui-text-critical);
}
.uui-typography .uui-info {
  color: var(--uui-text-info);
}
.uui-typography .uui-success {
  color: var(--uui-text-success);
}
.uui-typography .uui-warning {
  color: var(--uui-text-warning);
}
.uui-typography .uui-highlight {
  background-color: var(--uui-warning-10);
}

.uui-typography-size-12 {
  font-size: 12px;
}

.uui-typography-size-14 {
  font-size: 14px;
}

.uui-typography-size-16 {
  font-size: 16px;
}

.root {
  --uui-notification_card-bg: var(--uui-surface-main);
  --uui-notification_card-border: var(--uui-color-50);
  --uui-notification_card-fill: var(--uui-color-50);
  --uui-notification_card-border-radius: var(--uui-border-radius);
  --uui-notification_card-border-width: 0;
  --uui-notification_card-border-top-width: 6px;
  --uui-notification_card-shadow: var(--uui-shadow-level-2);
  border-style: solid;
  border-color: var(--uui-notification_card-border, transparent);
  border-width: var(--uui-notification_card-border-width);
  border-top-width: var(--uui-notification_card-border-top-width);
  padding: 6px 18px;
  width: 360px;
  background: var(--uui-notification_card-bg, var(--uui-surface-main));
  display: flex;
  overflow: hidden;
  border-radius: var(--uui-notification_card-border-radius);
  box-shadow: var(--uui-notification_card-shadow);
}
@media (max-width: 720px) {
  .root {
    width: 100%;
  }
}
.root .icon-wrapper {
  padding: 6px 0;
}
.root .action-wrapper {
  display: flex;
}
.root .action-link:not(:last-child) {
  margin-right: 12px;
}
.root .close-icon {
  padding: 6px 0;
}
.root .close-icon :global(.uui-icon) svg {
  height: 24px;
  width: 24px;
}
.root .main-path {
  width: 100%;
  display: flex;
  align-self: baseline;
}
.root .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 0 12px;
}
.root .close-wrapper {
  display: flex;
  align-items: flex-start;
}
.root .icon-wrapper :global(.uui-icon) {
  fill: var(--uui-notification_card-fill);
}

.notification-wrapper {
  padding: 6px 18px;
  width: 360px;
  background: var(--uui-surface-main);
  border-radius: 3px;
  display: flex;
  overflow: hidden;
  box-shadow: var(--uui-shadow-level-2);
}

.clear-button {
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0 18px;
}

@media (max-width: 720px) {
  .notification-wrapper {
    width: 100%;
  }
}