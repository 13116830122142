.uui-typography {
  font-family: var(--uui-font);
  color: var(--uui-text-primary);
}
.uui-typography .hero-header, .uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin: 0;
}
.uui-typography .hero-header {
  font-family: var(--uui-font);
  font-weight: 500;
  margin: 30px 0;
  font-size: 66px;
  line-height: 72px;
}
.uui-typography .promo-header, .uui-typography h1, .uui-typography h2, .uui-typography h3 {
  font-family: var(--uui-font);
  font-weight: 700;
}
.uui-typography h4, .uui-typography h5, .uui-typography h6 {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.uui-typography h1 {
  font-size: 42px;
}
.uui-typography h2 {
  font-size: 30px;
  letter-spacing: 0.1px;
}
.uui-typography h2 .promo-header {
  letter-spacing: 2px;
}
.uui-typography h3 {
  font-size: 24px;
}
.uui-typography h4 {
  font-size: 18px;
  letter-spacing: 0.1px;
}
.uui-typography h5 {
  font-size: 16px;
}
.uui-typography h6 {
  font-size: 14px;
}
.uui-typography b, .uui-typography strong {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography i, .uui-typography em {
  font-family: var(--uui-font);
  font-weight: 400;
  font-style: italic;
}
.uui-typography a {
  display: inline-flex;
  align-items: center;
  color: var(--uui-link);
  text-decoration: underline;
}
.uui-typography a:visited {
  color: var(--uui-link-visited);
}
.uui-typography a:visited:hover {
  color: var(--uui-link-visited-hover);
}
.uui-typography a:hover {
  color: var(--uui-link-hover);
}
.uui-typography code {
  padding: 0.125em 0.25em;
  color: var(--uui-text-primary);
  background-color: var(--uui-neutral-30);
  font-family: var(--uui-font-mono, monospace);
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography ol, .uui-typography ul {
  padding: 0;
}
.uui-typography li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.uui-typography pre {
  padding: 1.25em;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography img {
  max-width: 100%;
}
.uui-typography figure {
  margin: 0;
}
.uui-typography figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.uui-typography p {
  margin: 0.5em 0;
  line-height: 1.5;
}
.uui-typography .uui-critical {
  color: var(--uui-text-critical);
}
.uui-typography .uui-info {
  color: var(--uui-text-info);
}
.uui-typography .uui-success {
  color: var(--uui-text-success);
}
.uui-typography .uui-warning {
  color: var(--uui-text-warning);
}
.uui-typography .uui-highlight {
  background-color: var(--uui-warning-10);
}

.uui-typography-size-12 {
  font-size: 12px;
}

.uui-typography-size-14 {
  font-size: 14px;
}

.uui-typography-size-16 {
  font-size: 16px;
}

.root {
  --uui-checkbox-border: var(--uui-control-border);
  --uui-checkbox-border-hover: var(--uui-color-60);
  --uui-checkbox-border-disabled: var(--uui-control-border-disabled);
  --uui-checkbox-border-checked: var(--uui-color-50);
  --uui-checkbox-border-checked-disabled: var(--uui-color-20);
  --uui-checkbox-bg: var(--uui-control-bg);
  --uui-checkbox-bg-checked: var(--uui-color-50);
  --uui-checkbox-bg-disabled: var(--uui-control-bg-disabled);
  --uui-checkbox-bg-checked-hover: var(--uui-color-60);
  --uui-checkbox-bg-checked-disabled: var(--uui-color-20);
  --uui-checkbox-error: var(--uui-error-50);
  --uui-checkbox-fill: var(--uui-color-contrast);
  --uui-checkbox-label: var(--uui-text-primary);
  --uui-checkbox-label-disabled: var(--uui-text-disabled);
  --uui-checkbox-label-readonly: var(--uui-text-primary);
  --uui-checkbox-border-radius: var(--uui-border-radius);
  --uui-checkbox-font: var(--uui-font);
  --uui-checkbox-font-weight: 400;
}
.root:hover :global(.uui-checkbox), .root:active :global(.uui-checkbox), .root:focus-within :global(.uui-checkbox) {
  border-color: var(--uui-checkbox-border-hover);
}
.root:hover :global(.uui-checked), .root:active :global(.uui-checked), .root:focus-within :global(.uui-checked) {
  background-color: var(--uui-checkbox-bg-checked-hover);
}
.root :global(.uui-checkbox) {
  background-color: var(--uui-checkbox-bg);
  border: 1px solid var(--uui-checkbox-border);
  border-radius: var(--uui-checkbox-border-radius);
  position: relative;
}
.root :global(.uui-checkbox) :global(input[type="checkbox"]):focus-visible {
  outline: 2px solid var(--uui-outline-focus);
  outline-offset: 2px;
  border-radius: 2px;
  position: absolute;
  width: inherit;
  height: inherit;
}
.root :global(.uui-input-label) {
  font-family: var(--uui-checkbox-font);
  font-weight: var(--uui-checkbox-font-weigth);
  color: var(--uui-checkbox-label);
  margin-left: 12px;
  flex-grow: 1;
}
.root :global(.uui-checked) {
  background-color: var(--uui-checkbox-bg-checked);
  border-color: var(--uui-checkbox-border-checked);
  fill: var(--uui-checkbox-fill);
}
.root:global(.uui-disabled), .root:global(.uui-readonly) {
  cursor: default;
}
.root:global(.uui-disabled) :global(.uui-checkbox), .root:global(.uui-readonly) :global(.uui-checkbox) {
  cursor: default;
  background-color: var(--uui-checkbox-bg-disabled);
  border-color: var(--uui-checkbox-border-disabled);
}
.root:global(.uui-disabled):hover:global(.uui-checkbox), .root:global(.uui-disabled):global(.uui-checked):global(.uui-checkbox), .root:global(.uui-disabled):focus-within:global(.uui-checkbox), .root:global(.uui-readonly):hover:global(.uui-checkbox), .root:global(.uui-readonly):global(.uui-checked):global(.uui-checkbox), .root:global(.uui-readonly):focus-within:global(.uui-checkbox) {
  border-color: var(--uui-checkbox-border-checked-disabled);
}
.root:global(.uui-disabled):hover:global(.uui-icon), .root:global(.uui-disabled):global(.uui-checked):global(.uui-icon), .root:global(.uui-disabled):focus-within:global(.uui-icon), .root:global(.uui-readonly):hover:global(.uui-icon), .root:global(.uui-readonly):global(.uui-checked):global(.uui-icon), .root:global(.uui-readonly):focus-within:global(.uui-icon) {
  background-color: var(--uui-checkbox-bg-checked-disabled);
  border-color: var(--uui-checkbox-border-checked-disabled);
}
.root:global(.uui-disabled) :global(.uui-input-label), .root:global(.uui-readonly) :global(.uui-input-label) {
  color: var(--uui-checkbox-label-disabled);
}
.root:global(.uui-disabled) :global(.uui-checked), .root:global(.uui-readonly) :global(.uui-checked) {
  background-color: var(--uui-checkbox-bg-checked-disabled);
  border-color: var(--uui-checkbox-border-checked-disabled);
}
.root:global(.uui-readonly) :global(.uui-input-label) {
  color: var(--uui-checkbox-label-readonly);
}
.root:global(.uui-invalid) :global(.uui-checkbox) {
  border-color: var(--uui-checkbox-error);
  fill: var(--uui-checkbox-error);
}
.root:global(.uui-invalid) :global(.uui-checkbox):hover, .root:global(.uui-invalid) :global(.uui-checkbox):active, .root:global(.uui-invalid) :global(.uui-checkbox):focus-within {
  border-color: var(--uui-checkbox-error);
  fill: var(--uui-checkbox-error);
}
.root:global(.uui-invalid) :global(.uui-checkbox):global(.uui-checked) {
  fill: var(--uui-checkbox-fill);
  background-color: var(--uui-checkbox-error);
  border-color: var(--uui-checkbox-error);
}
.root:global(.uui-invalid) :global(.uui-checkbox):global(.uui-checked):hover, .root:global(.uui-invalid) :global(.uui-checkbox):global(.uui-checked):active, .root:global(.uui-invalid) :global(.uui-checkbox):global(.uui-checked):focus-within {
  background-color: var(--uui-checkbox-error);
  border-color: var(--uui-checkbox-error);
}
.root:global(.uui-invalid) :global(.uui-input-label) {
  color: var(--uui-checkbox-error);
}
.root.size-18 :global(.uui-checkbox) {
  box-sizing: border-box;
  width: 18px;
  height: 18px;
}
.root.size-18 :global(.uui-checkbox) :global(.uui-icon) svg {
  height: 18px;
  width: 18px;
}
.root.size-18 :global(.uui-input-label) {
  font-size: 14px;
  line-height: 18px;
}
.root.size-12 :global(.uui-checkbox) {
  box-sizing: border-box;
  width: 12px;
  height: 12px;
}
.root.size-12 :global(.uui-checkbox) :global(.uui-icon) svg {
  height: 12px;
  width: 12px;
}
.root.size-12 :global(.uui-input-label) {
  font-size: 12px;
  line-height: 12px;
}
.root.size-12 :global(.uui-input-label) {
  margin-left: 6px;
}
.root.mode-cell {
  justify-content: center;
}