.global-menu-btn {
  display: block;
  height: 60px;
  width: 60px;
  fill: var(--uui-main_menu-icon);
  flex-shrink: 0;
  cursor: pointer;
  background: transparent;
  border: 0 none;
  padding: 0;
}
.global-menu-btn:focus, .global-menu-btn:hover {
  background-color: var(--uui-main_menu-hover);
  outline: none;
}
.global-menu-btn:active {
  background-color: var(--uui-main_menu-active);
}
.global-menu-btn:focus-visible {
  outline: 2px solid var(--uui-outline-focus);
  outline-offset: -2px;
  border-radius: 0;
}

.global-menu-icon {
  align-items: center;
}