.root {
  --uui-switch-bg: var(--uui-neutral-40);
  --uui-switch-bg-checked: var(--uui-color-50);
  --uui-switch-bg-hover: var(--uui-control-bg-hover);
  --uui-switch-bg-checked-hover: var(--uui-color-60);
  --uui-switch-bg-checked-disabled: var(--uui-color-20);
  --uui-switch-bg-disabled: var(--uui-control-bg-disabled);
  --uui-switch-border: var(--uui-control-border);
  --uui-switch-border-hover: var(--uui-control-bg-hover);
  --uui-switch-border-checked: var(--uui-color-50);
  --uui-switch-border-checked-hover: var(--uui-color-60);
  --uui-switch-border-checked-disabled: var(--uui-color-20);
  --uui-switch-border-disabled: var(--uui-control-border-disabled);
  --uui-switch-toggler-bg: var(--uui-neutral-0);
  --uui-switch-toggler-bg-disabled: var(--uui-control-bg-disabled);
  --uui-switch-toggler-border-checked: var(--uui-color-50);
  --uui-switch-toggler-border-hover: var(--uui-control-bg-hover);
  --uui-switch-toggler-border-disabled: var(--uui-control-border-disabled);
  --uui-switch-toggler-border-checked-hover: var(--uui-color-60);
  --uui-switch-toggler-border-checked-disabled: var(--uui-color-20);
  --uui-switch-label: var(--uui-text-primary);
  --uui-switch-label-disabled: var(--uui-text-disabled);
  --uui-switch-font: var(--uui-font);
  --uui-switch-font-weight: 400;
}
.root.size-12 :global(.uui-switch-body) {
  height: 12px;
  width: 24px;
}
.root.size-12 :global(.uui-switch-toggler) {
  height: 12px;
  width: 12px;
}
.root.size-12 :global(.uui-input-label) {
  font-size: 12px;
}
.root.size-12 :global(.uui-checked) :global(.uui-switch-toggler) {
  transform: translateX(12px);
}
.root.size-18 :global(.uui-switch-body) {
  height: 18px;
  width: 36px;
}
.root.size-18 :global(.uui-switch-toggler) {
  height: 18px;
  width: 18px;
}
.root.size-18 :global(.uui-input-label) {
  font-size: 14px;
}
.root.size-18 :global(.uui-checked) :global(.uui-switch-toggler) {
  transform: translateX(18px);
}
.root.size-24 :global(.uui-switch-body) {
  height: 24px;
  width: 48px;
}
.root.size-24 :global(.uui-switch-toggler) {
  height: 24px;
  width: 24px;
}
.root.size-24 :global(.uui-input-label) {
  font-size: 14px;
}
.root.size-24 :global(.uui-checked) :global(.uui-switch-toggler) {
  transform: translateX(24px);
}
.root :global(.uui-switch-body) {
  background-color: var(--uui-switch-bg);
  border: 1px solid var(--uui-switch-border);
  width: 36px;
  border-radius: 18px;
  box-sizing: border-box;
}
.root :global(.uui-switch-body) :global(input[type="checkbox"]):focus-visible {
  outline: 2px solid var(--uui-outline-focus);
  outline-offset: 2px;
  border-radius: inherit;
  width: inherit;
  height: inherit;
  position: absolute;
  left: 0;
}
.root :global(.uui-switch-toggler) {
  left: -1px;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: var(--uui-switch-toggler-bg);
  border: 1px solid var(--uui-switch-border);
  transition: 0.1s transform cubic-bezier(0.4, 0, 0.2, 1);
}
.root :global(.uui-input-label) {
  font-family: var(--uui-switch-font);
  font-weight: var(--uui-switch-font-weight, normal);
  color: var(--uui-switch-label);
  margin-left: 11px;
}
.root:hover :global(.uui-switch-body), .root:focus-within :global(.uui-switch-body) {
  background-color: var(--uui-switch-bg-hover);
  border-color: var(--uui-switch-border-hover);
}
.root:hover :global(.uui-switch-toggler), .root:focus-within :global(.uui-switch-toggler) {
  border-color: var(--uui-switch-toggler-border-hover);
}
.root:hover :global(.uui-checked):global(.uui-switch-body), .root:focus-within :global(.uui-checked):global(.uui-switch-body) {
  background-color: var(--uui-switch-bg-checked-hover);
  border-color: var(--uui-switch-border-checked-hover);
}
.root:hover :global(.uui-checked) :global(.uui-switch-toggler), .root:focus-within :global(.uui-checked) :global(.uui-switch-toggler) {
  border-color: var(--uui-switch-toggler-border-checked-hover);
}
.root :global(.uui-checked):global(.uui-switch-body) {
  background-color: var(--uui-switch-bg-checked);
  border: 1px solid var(--uui-switch-border-checked);
}
.root :global(.uui-checked) :global(.uui-switch-toggler) {
  border: 1px solid var(--uui-switch-toggler-border-checked);
}
.root:global(.uui-readonly),
.root:global(.uui-readonly) :global(.uui-switch-toggler),
.root:global(.uui-readonly) :global(.uui-switch-body),
.root:global(.uui-readonly) :global(.uui-input-label), .root:global(.uui-disabled),
.root:global(.uui-disabled) :global(.uui-switch-toggler),
.root:global(.uui-disabled) :global(.uui-switch-body),
.root:global(.uui-disabled) :global(.uui-input-label) {
  cursor: default;
}
.root:global(.uui-readonly) :global(.uui-switch-body), .root:global(.uui-disabled) :global(.uui-switch-body) {
  background-color: var(--uui-switch-bg-disabled);
  border: 1px solid var(--uui-switch-border-disabled);
}
.root:global(.uui-readonly) :global(.uui-switch-toggler), .root:global(.uui-readonly):hover :global(.uui-switch-toggler), .root:global(.uui-readonly):focus-within :global(.uui-switch-toggler), .root:global(.uui-disabled) :global(.uui-switch-toggler), .root:global(.uui-disabled):hover :global(.uui-switch-toggler), .root:global(.uui-disabled):focus-within :global(.uui-switch-toggler) {
  background-color: var(--uui-switch-toggler-bg-disabled);
  border-color: var(--uui-switch-toggler-border-disabled);
}
.root:global(.uui-readonly) :global(.uui-checked):global(.uui-switch-body), .root:global(.uui-disabled) :global(.uui-checked):global(.uui-switch-body) {
  background-color: var(--uui-switch-bg-checked-disabled);
  border: 1px solid var(--uui-switch-border-checked-disabled);
}
.root:global(.uui-readonly) :global(.uui-checked) :global(.uui-switch-toggler), .root:global(.uui-disabled) :global(.uui-checked) :global(.uui-switch-toggler) {
  background-color: var(--uui-switch-toggler-bg-disabled);
  border-color: var(--uui-switch-toggler-border-checked-disabled);
}
.root:global(.uui-disabled) :global(.uui-input-label) {
  color: var(--uui-switch-label-disabled);
}