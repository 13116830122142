/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 300" instead "$font-sans-light"!
 */
/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 400" instead "$font-sans"!
 */
/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 600" instead "$font-sans-semibold"!
 */
/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 700" instead "$font-sans-bold"!
 */
/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 400" & "font-style: italic" instead "$font-sans-italic"!
 */
/* Night scale */
.root :global(.uui-tooltip-body) {
  background-color: #474A59;
  padding: 0 10px;
  color: white;
  line-height: 24px;
  font-size: 14px;
  font-family: "Sans Regular", Arial, sans-serif;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}
.root :global(.uui-tooltip-arrow) {
  border: 5px solid transparent;
}
.root :global(.uui-slider-scale-number) {
  font-size: 11px;
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
  margin-top: 10px;
}
.root :global(.uui-slider-scale-dot) {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  top: -1px;
}
.root:global(.uui-slider-container) {
  height: 12px;
}
.root:global(.uui-slider-container) :global(.uui-slider) {
  margin: 0 6px 0 6px;
  width: 100%;
  height: 3px;
  border-radius: 10px;
  background: #ACAFBF;
  min-width: 100px;
}
.root:global(.uui-slider-container) :global(.uui-slider-scale) {
  height: 6px;
}
.root:global(.uui-slider-container) :global(.uui-slider-handle) {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #009ECC;
  cursor: pointer;
}
.root:global(.uui-slider-container) :global(.uui-slider-filled) {
  margin: 0 6px;
  height: 3px;
  border-radius: 10px;
  background-color: #009ECC;
}
.root:global(.uui-slider-container) :global(.uui-slider-scale-dot) {
  background-color: #EBEDF5;
  border: 1px #ACAFBF solid;
}
.root:global(.uui-slider-container):hover :global(.uui-slider) {
  background-color: #A0DDEE;
}
.root:global(.uui-slider-container):hover :global(.uui-slider-scale-dot):not(:global(.uui-slider-scale-filled-dot)) {
  border-color: #A0DDEE;
  background-color: #A0DDEE;
}
.root:global(.uui-slider-container) :global(.uui-slider-scale-filled-dot) {
  background-color: #009ECC;
  border-color: #009ECC;
}
.root:global(.uui-slider-container):global(.uui-active) :global(.uui-slider) {
  background-color: #A0DDEE;
}
.root:global(.uui-slider-container):global(.uui-active) :global(.uui-slider-scale-dot):not(:global(.uui-slider-scale-filled-dot)) {
  border-color: #A0DDEE;
  background-color: #A0DDEE;
}
.root:global(.uui-slider-container):global(.uui-disabled) {
  pointer-events: none;
}
.root:global(.uui-slider-container):global(.uui-disabled) :global(.uui-slider-filled) {
  background-color: #CED0DB;
}
.root:global(.uui-slider-container):global(.uui-disabled) :global(.uui-slider-handle) {
  background-color: #CED0DB;
}
.root:global(.uui-slider-container):global(.uui-disabled) :global(.uui-slider) {
  background-color: #CED0DB;
}
.root:global(.uui-slider-container):global(.uui-disabled) :global(.uui-slider-scale-filled-dot) {
  background-color: #CED0DB;
  border-color: #CED0DB;
}