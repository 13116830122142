.uui-typography {
  font-family: var(--uui-font);
  color: var(--uui-text-primary);
}
.uui-typography .hero-header, .uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin: 0;
}
.uui-typography .hero-header {
  font-family: var(--uui-font);
  font-weight: 500;
  margin: 30px 0;
  font-size: 66px;
  line-height: 72px;
}
.uui-typography .promo-header, .uui-typography h1, .uui-typography h2, .uui-typography h3 {
  font-family: var(--uui-font);
  font-weight: 700;
}
.uui-typography h4, .uui-typography h5, .uui-typography h6 {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.uui-typography h1 {
  font-size: 42px;
}
.uui-typography h2 {
  font-size: 30px;
  letter-spacing: 0.1px;
}
.uui-typography h2 .promo-header {
  letter-spacing: 2px;
}
.uui-typography h3 {
  font-size: 24px;
}
.uui-typography h4 {
  font-size: 18px;
  letter-spacing: 0.1px;
}
.uui-typography h5 {
  font-size: 16px;
}
.uui-typography h6 {
  font-size: 14px;
}
.uui-typography b, .uui-typography strong {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography i, .uui-typography em {
  font-family: var(--uui-font);
  font-weight: 400;
  font-style: italic;
}
.uui-typography a {
  display: inline-flex;
  align-items: center;
  color: var(--uui-link);
  text-decoration: underline;
}
.uui-typography a:visited {
  color: var(--uui-link-visited);
}
.uui-typography a:visited:hover {
  color: var(--uui-link-visited-hover);
}
.uui-typography a:hover {
  color: var(--uui-link-hover);
}
.uui-typography code {
  padding: 0.125em 0.25em;
  color: var(--uui-text-primary);
  background-color: var(--uui-neutral-30);
  font-family: var(--uui-font-mono, monospace);
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography ol, .uui-typography ul {
  padding: 0;
}
.uui-typography li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.uui-typography pre {
  padding: 1.25em;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography img {
  max-width: 100%;
}
.uui-typography figure {
  margin: 0;
}
.uui-typography figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.uui-typography p {
  margin: 0.5em 0;
  line-height: 1.5;
}
.uui-typography .uui-critical {
  color: var(--uui-text-critical);
}
.uui-typography .uui-info {
  color: var(--uui-text-info);
}
.uui-typography .uui-success {
  color: var(--uui-text-success);
}
.uui-typography .uui-warning {
  color: var(--uui-text-warning);
}
.uui-typography .uui-highlight {
  background-color: var(--uui-warning-10);
}

.uui-typography-size-12 {
  font-size: 12px;
}

.uui-typography-size-14 {
  font-size: 14px;
}

.uui-typography-size-16 {
  font-size: 16px;
}

.root {
  --uui-btn-bg: var(--uui-color-50);
  --uui-btn-bg-hover: var(--uui-color-60);
  --uui-btn-bg-active: var(--uui-color-70);
  --uui-btn-bg-disabled: var(--uui-neutral-30);
  --uui-btn-border: var(--uui-color-50);
  --uui-btn-border-hover: var(--uui-color-60);
  --uui-btn-border-active: var(--uui-color-70);
  --uui-btn-border-disabled: var(--uui-neutral-30);
  --uui-btn-caption: var(--uui-color-contrast);
  --uui-btn-caption-disabled: var(--uui-text-disabled);
  --uui-btn-border-radius: var(--uui-border-radius);
  --uui-btn-font: var(--uui-font);
  --uui-btn-font-weight: var(--uui-font-weight, 600);
  background-color: var(--uui-btn-bg);
  color: var(--uui-btn-caption);
  fill: var(--uui-btn-caption);
  border-color: var(--uui-btn-border);
}
.root:global(.-clickable):hover {
  background-color: var(--uui-btn-bg-hover);
  border-color: var(--uui-btn-border-hover);
}
.root:global(.-clickable):active {
  background-color: var(--uui-btn-bg-active);
  border-color: var(--uui-btn-border-active);
}
.root:global(.uui-button-box) {
  border-style: solid;
  justify-content: center;
  border-radius: var(--uui-btn-border-radius);
}
.root:focus-visible {
  outline: 2px solid var(--uui-outline-focus);
  outline-offset: 2px;
  border-radius: 2px;
}
.root :global(.uui-caption) {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  text-align: center;
  flex: 0 1 auto;
  font-family: var(--uui-btn-font);
  font-weight: var(--uui-btn-font-weight, normal);
  user-select: none;
}
.root:is(:global(.-clickable), :hover, :active, :focus):global(.uui-disabled):global(.uui-fill-solid), .root:global(.uui-disabled):global(.uui-fill-solid) {
  background-color: var(--uui-btn-bg-disabled);
  color: var(--uui-btn-caption-disabled);
  fill: var(--uui-btn-caption-disabled);
  border-color: var(--uui-btn-border-disabled);
}
.root:is(:global(.-clickable), :hover, :active, :focus):global(.uui-disabled):global(.uui-fill-outline), .root:global(.uui-disabled):global(.uui-fill-outline) {
  background-color: var(--uui-surface-main);
  color: var(--uui-btn-caption-disabled);
  fill: var(--uui-btn-caption-disabled);
  border-color: var(--uui-btn-border-disabled);
}
.root:is(:global(.-clickable), :hover, :active, :focus):global(.uui-disabled):global(.uui-fill-none), .root:global(.uui-disabled):global(.uui-fill-none) {
  background-color: transparent;
  color: var(--uui-btn-caption-disabled);
  fill: var(--uui-btn-caption-disabled);
  border-color: var(--uui-btn-border-disabled);
}
.root:is(:global(.-clickable), :hover, :active, :focus):global(.uui-disabled):global(.uui-fill-ghost), .root:global(.uui-disabled):global(.uui-fill-ghost) {
  background-color: transparent;
  color: var(--uui-btn-caption-disabled);
  fill: var(--uui-btn-caption-disabled);
  border-color: transparent;
}
.root:global(.uui-fill-solid) {
  --uui-btn-bg: var(--uui-color-50);
  --uui-btn-bg-hover: var(--uui-color-60);
  --uui-btn-bg-active: var(--uui-color-70);
  --uui-btn-border: var(--uui-color-50);
  --uui-btn-border-hover: var(--uui-color-60);
  --uui-btn-border-active: var(--uui-color-70);
  --uui-btn-caption: var(--uui-color-contrast);
  --uui-btn-caption-disabled: var(--uui-text-disabled);
}
.root:global(.uui-fill-solid):global(.uui-color-white):active {
  background-color: var(--uui-neutral-30);
  border-color: var(--uui-neutral-30);
}
.root:global(.uui-fill-solid):global(.uui-color-white):global(.uui-disabled) {
  background-color: color-mix(in srgb, var(--uui-neutral-0) 10%, transparent);
  border-color: transparent;
  color: color-mix(in srgb, var(--uui-neutral-0) 40%, transparent);
  fill: color-mix(in srgb, var(--uui-neutral-0) 40%, transparent);
}
.root:global(.uui-fill-outline) {
  --uui-btn-bg: var(--uui-surface-main);
  --uui-btn-bg-hover: var(--uui-color-10);
  --uui-btn-bg-active: var(--uui-color-20);
  --uui-btn-bg-disabled: transparent;
  --uui-btn-border: var(--uui-color-50);
  --uui-btn-border-hover: var(--uui-color-50);
  --uui-btn-border-active: var(--uui-color-50);
  --uui-btn-caption: var(--uui-color-50);
  --uui-btn-caption-disabled: var(--uui-text-disabled);
}
.root:global(.uui-fill-outline):global(.uui-color-white) {
  --uui-btn-bg: var(--uui-color-5);
}
.root:global(.uui-fill-outline):global(.uui-color-white):global(.uui-disabled) {
  background-color: color-mix(in srgb, var(--uui-neutral-0) 10%, transparent);
  border-color: color-mix(in srgb, var(--uui-neutral-0) 30%, transparent);
  color: color-mix(in srgb, var(--uui-neutral-0) 40%, transparent);
  fill: color-mix(in srgb, var(--uui-neutral-0) 40%, transparent);
}
.root:global(.uui-fill-none) {
  --uui-btn-bg: transparent;
  --uui-btn-bg-hover: var(--uui-color-10);
  --uui-btn-bg-active: var(--uui-color-20);
  --uui-btn-bg-disabled: transparent;
  --uui-btn-border: var(--uui-color-50);
  --uui-btn-border-hover: var(--uui-color-50);
  --uui-btn-border-active: var(--uui-color-50);
  --uui-btn-caption: var(--uui-color-50);
  --uui-btn-caption-disabled: var(--uui-text-disabled);
}
.root:global(.uui-fill-none):global(.uui-color-white):global(.uui-disabled) {
  background-color: color-mix(in srgb, var(--uui-neutral-0) 0%, transparent);
  border-color: color-mix(in srgb, var(--uui-neutral-0) 30%, transparent);
  color: color-mix(in srgb, var(--uui-neutral-0) 40%, transparent);
  fill: color-mix(in srgb, var(--uui-neutral-0) 40%, transparent);
}
.root:global(.uui-fill-ghost) {
  --uui-btn-bg: transparent;
  --uui-btn-bg-hover: var(--uui-color-10);
  --uui-btn-bg-active: var(--uui-color-20);
  --uui-btn-bg-disabled: transparent;
  --uui-btn-border: transparent;
  --uui-btn-border-hover: var(--uui-color-10);
  --uui-btn-border-active: var(--uui-color-20);
  --uui-btn-border-disabled: transparent;
  --uui-btn-caption: var(--uui-color-50);
  --uui-btn-caption-disabled: var(--uui-text-disabled);
}
.root:global(.uui-fill-ghost):global(.uui-color-white) {
  --uui-btn-border-hover: transparent;
  --uui-btn-border-active: transparent;
}
.root:global(.uui-fill-ghost):global(.uui-color-white):global(.uui-disabled) {
  background-color: color-mix(in srgb, var(--uui-neutral-0) 0%, transparent);
  border-color: transparent;
  color: color-mix(in srgb, var(--uui-neutral-0) 40%, transparent);
  fill: color-mix(in srgb, var(--uui-neutral-0) 40%, transparent);
}
.root:global.uui-size-18 {
  min-width: 18px;
  padding-left: 2px;
  padding-right: 2px;
}
.root:global.uui-size-18 :global(.uui-caption) {
  padding-left: 3px;
  padding-right: 3px;
}
.root:global.uui-size-18:global(.uui-button-box) {
  min-height: 18px;
  border-width: 1px;
}
.root:global.uui-size-18 :global(.uui-caption) {
  line-height: 12px;
  font-size: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.root:global.uui-size-18 :global(.uui-icon) svg {
  height: 12px;
  width: inherit;
}
.root:global.uui-size-24 {
  min-width: 24px;
  padding-left: 5px;
  padding-right: 5px;
}
.root:global.uui-size-24 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.root:global.uui-size-24:global(.uui-button-box) {
  min-height: 24px;
  border-width: 1px;
}
.root:global.uui-size-24 :global(.uui-caption) {
  line-height: 18px;
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.root:global.uui-size-24 :global(.uui-icon) svg {
  height: 12px;
  width: inherit;
}
.root:global.uui-size-30 {
  min-width: 30px;
  padding-left: 5px;
  padding-right: 5px;
}
.root:global.uui-size-30 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.root:global.uui-size-30:global(.uui-button-box) {
  min-height: 30px;
  border-width: 1px;
}
.root:global.uui-size-30 :global(.uui-caption) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.root:global.uui-size-30 :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.root:global.uui-size-36 {
  min-width: 36px;
  padding-left: 5px;
  padding-right: 5px;
}
.root:global.uui-size-36 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.root:global.uui-size-36:global(.uui-button-box) {
  min-height: 36px;
  border-width: 1px;
}
.root:global.uui-size-36 :global(.uui-caption) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.root:global.uui-size-36 :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.root:global.uui-size-42 {
  min-width: 42px;
  padding-left: 11px;
  padding-right: 11px;
}
.root:global.uui-size-42 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.root:global.uui-size-42:global(.uui-button-box) {
  min-height: 42px;
  border-width: 1px;
}
.root:global.uui-size-42 :global(.uui-caption) {
  line-height: 24px;
  font-size: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
}
.root:global.uui-size-42 :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.root:global.uui-size-48 {
  min-width: 48px;
  padding-left: 11px;
  padding-right: 11px;
}
.root:global.uui-size-48 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.root:global.uui-size-48:global(.uui-button-box) {
  min-height: 48px;
  border-width: 1px;
}
.root:global.uui-size-48 :global(.uui-caption) {
  line-height: 24px;
  font-size: 16px;
  padding-top: 11px;
  padding-bottom: 11px;
  font-size: 16px;
}
.root:global.uui-size-48 :global(.uui-icon) svg {
  height: 24px;
  width: inherit;
}