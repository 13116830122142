.cell {
  position: relative;
  box-sizing: border-box;
  background-clip: padding-box;
  display: flex;
  align-items: flex-start;
  background-color: var(--uui-dt-cell-bg);
  border-width: var(--uui-dt-cell-border-width);
  margin-left: calc(var(--uui-dt-cell-border-width) * -1);
  border-left-style: solid;
  border-right-style: solid;
  border-top-style: none;
  border-bottom-style: none;
  border-left-color: transparent;
  border-right-color: transparent;
}
.cell:first-child {
  border-left-color: transparent;
}
.cell:last-child {
  border-right-color: transparent;
}

.content-wrapper {
  display: flex;
  min-width: 0;
  flex: 1;
}

.editor-wrapper {
  flex: 1 1 0;
  min-width: 0;
  position: relative;
  align-self: stretch;
  background-color: var(--uui-dt-cell-editor-bg);
}