.style-round:global.uui-size-18 {
  border-radius: 10px;
}
.style-round:global.uui-size-24 {
  border-radius: 12px;
}
.style-round:global.uui-size-30 {
  border-radius: 15px;
}
.style-round:global.uui-size-36 {
  border-radius: 18px;
}
.style-round:global.uui-size-42 {
  border-radius: 21px;
}
.style-round:global.uui-size-48 {
  border-radius: 24px;
}