@font-face {
  font-family: "Sans Regular";
  font-style: normal;
  font-stretch: normal;
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Regular.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Regular.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-Regular.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Sans Light";
  font-style: normal;
  font-stretch: normal;
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Light.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Light.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-Light.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Sans Bold";
  font-style: normal;
  font-stretch: normal;
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Bold.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Bold.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-Bold.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Sans Semibold";
  font-style: normal;
  font-stretch: normal;
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Semibold.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Semibold.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-Semibold.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-Semibold.ttf") format("truetype");
}
@font-face {
  font-family: "Sans Italic";
  font-style: normal;
  font-stretch: normal;
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-It.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-It.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-It.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-It.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: normal;
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/RobotoMono-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Redacted";
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/redacted/redacted-regular.eot");
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/redacted/redacted-regular.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/redacted/redacted-regular.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/redacted/redacted-regular.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/redacted/redacted-regular.svg#filename") format("svg");
  font-display: block;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-ExtraLight.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-ExtraLight.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-ExtraLight.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-ExtraLightIt.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-ExtraLightIt.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-ExtraLightIt.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-ExtraLightIt.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Light.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Light.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-Light.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-LightIt.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-LightIt.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-LightIt.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-LightIt.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Regular.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Regular.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-Regular.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-It.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-It.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-It.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-It.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Semibold.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Semibold.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-Semibold.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-SemiboldIt.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-SemiboldIt.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-SemiboldIt.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-SemiboldIt.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Bold.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Bold.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-Bold.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-BoldIt.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-BoldIt.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-BoldIt.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-BoldIt.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Black.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Black.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-Black.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-BlackIt.ttf.woff2") format("woff2"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-BlackIt.otf.woff") format("woff"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-BlackIt.otf") format("opentype"), url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-BlackIt.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
/*  Name convention  */
/* --uui-'component name or group of components name'-'styled part name'-'states(optional)'-'hover/active/focus(optional)' */
.uui-theme-loveship {
  /***************/
  /*** Palette ***/
  /***************/
  --black: #000000;
  --white: #FFFFFF;
  --cobalt-5: #F8FAFF;
  --cobalt-10: #D9E2FC;
  --cobalt-20: #AEC0F5;
  --cobalt-50: #0F98FF;
  --cobalt-60: #006FE5;
  --cobalt-70: #0954A5;
  --cyan-5: #F6FEFE;
  --cyan-10: #D1FAFA;
  --cyan-20: #AAEEEE;
  --cyan-50: #14CCCC;
  --cyan-60: #12B5B5;
  --cyan-70: #0B6F6F;
  --fire-5: #FEF6F6;
  --fire-10: #FDE1E1;
  --fire-20: #FBB6B6;
  --fire-50: #FF4242;
  --fire-60: #E22A2A;
  --fire-70: #AD0000;
  --fuchsia-5: #FFF7FB;
  --fuchsia-10: #F9D8E7;
  --fuchsia-20: #EDADC8;
  --fuchsia-50: #EA4386;
  --fuchsia-60: #D61E68;
  --fuchsia-70: #AE1955;
  --grass-5: #FCFFF5;
  --grass-10: #EBF3D8;
  --grass-20: #C1E288;
  --grass-50: #67A300;
  --grass-60: #528500;
  --grass-70: #396F1F;
  --mint-5: #F2FCF5;
  --mint-10: #DDF3E4;
  --mint-20: #B4DFC4;
  --mint-50: #4FC48C;
  --mint-60: #31AA70;
  --mint-70: #236E4A;
  --night50: #FAFAFC;
  --night100: #F5F6FA;
  --night200: #EBEDF5;
  --night300: #E1E3EB;
  --night400: #CED0DB;
  --night500: #ACAFBF;
  --night600: #6C6F80;
  --night700: #474A59;
  --night800: #303240;
  --night900: #1D1E26;
  --orange-5: #FEF8F4;
  --orange-10: #FFE8D7;
  --orange-20: #FFCCA7;
  --orange-50: #FF8B3E;
  --orange-60: #F76B0D;
  --orange-70: #BD4B00;
  --purple-5: #FDF6FE;
  --purple-10: #F2CCFA;
  --purple-20: #E79DF5;
  --purple-50: #B114D1;
  --purple-60: #860F9E;
  --purple-70: #5E0B6F;
  --sky-5: #F5FDFF;
  --sky-10: #E1F4FA;
  --sky-20: #A0DDEE;
  --sky-50: #009ECC;
  --sky-60: #0086AD;
  --sky-70: #006B8A;
  --sun-5: #FFFCF5;
  --sun-10: #FFEDC9;
  --sun-20: #FFD785;
  --sun-50: #FCAA00;
  --sun-60: #FF9000;
  --sun-70: #BD5800;
  --violet-5: #F8F6FE;
  --violet-10: #DBCCFA;
  --violet-20: #BB9DF5;
  --violet-50: #773CEC;
  --violet-60: #5514D6;
  --violet-70: #40109E;
  --yellow-5: #FFFFF5;
  --yellow-10: #FFFFE5;
  --yellow-20: #FFFCA4;
  --yellow-50: #FDD63B;
  --yellow-60: #EDBE02;
  --yellow-70: #BC7E02;
  /*********************/
  /*** Core Semantic ***/
  /*********************/
  --uui-accent-5: var(--grass-5);
  --uui-accent-10: var(--grass-10);
  --uui-accent-20: var(--grass-20);
  --uui-accent-50: var(--grass-50);
  --uui-accent-60: var(--grass-60);
  --uui-accent-70: var(--grass-70);
  --uui-critical-5: var(--fire-5);
  --uui-critical-10: var(--fire-10);
  --uui-critical-20: var(--fire-20);
  --uui-critical-50: var(--fire-50);
  --uui-critical-60: var(--fire-60);
  --uui-critical-70: var(--fire-70);
  --uui-error-5: var(--fire-5);
  --uui-error-10: var(--fire-10);
  --uui-error-20: var(--fire-20);
  --uui-error-50: var(--fire-50);
  --uui-error-60: var(--fire-60);
  --uui-error-70: var(--fire-70);
  --uui-info-5: var(--sky-5);
  --uui-info-10: var(--sky-10);
  --uui-info-20: var(--sky-20);
  --uui-info-50: var(--sky-50);
  --uui-info-60: var(--sky-60);
  --uui-info-70: var(--sky-70);
  --uui-primary-5: var(--sky-5);
  --uui-primary-10: var(--sky-10);
  --uui-primary-20: var(--sky-20);
  --uui-primary-50: var(--sky-50);
  --uui-primary-60: var(--sky-60);
  --uui-primary-70: var(--sky-70);
  --uui-secondary-5: var(--night100);
  --uui-secondary-10: var(--night200);
  --uui-secondary-20: var(--night300);
  --uui-secondary-50: var(--night600);
  --uui-secondary-60: var(--night700);
  --uui-secondary-70: var(--night800);
  --uui-success-5: var(--grass-5);
  --uui-success-10: var(--grass-10);
  --uui-success-20: var(--grass-20);
  --uui-success-50: var(--grass-50);
  --uui-success-60: var(--grass-60);
  --uui-success-70: var(--grass-70);
  --uui-warning-5: var(--sun-5);
  --uui-warning-10: var(--sun-10);
  --uui-warning-20: var(--sun-20);
  --uui-warning-50: var(--sun-50);
  --uui-warning-60: var(--sun-60);
  --uui-warning-70: var(--sun-70);
  /********************/
  /*** Core Neutral ***/
  /********************/
  --uui-neutral-0: var(--white);
  --uui-neutral-5: var(--night50);
  --uui-neutral-10: var(--night100);
  --uui-neutral-20: var(--night200);
  --uui-neutral-30: var(--night300);
  --uui-neutral-40: var(--night400);
  --uui-neutral-50: var(--night500);
  --uui-neutral-60: var(--night600);
  --uui-neutral-70: var(--night700);
  --uui-neutral-80: var(--night800);
  --uui-neutral-90: var(--night900);
  --uui-neutral-100: var(--black);
  /************/
  /*** Core ***/
  /************/
  --uui-app-bg: var(--uui-neutral-10);
  --uui-divider: var(--uui-neutral-40);
  --uui-divider-light: var(--uui-neutral-30);
  --uui-overlay: #1D1E26CC;
  --uui-surface-higher: var(--uui-neutral-10);
  --uui-surface-highest: var(--uui-neutral-20);
  --uui-surface-main: var(--uui-neutral-0);
  --uui-surface-sunken: var(--uui-neutral-20);
  --uui-border-radius: 3;
  --uui-control-bg: var(--uui-surface-main);
  --uui-control-bg-disabled: var(--uui-neutral-5);
  --uui-control-bg-hover: var(--uui-neutral-60);
  --uui-control-border: var(--uui-neutral-40);
  --uui-control-border-disabled: var(--uui-neutral-30);
  --uui-control-border-focus: var(--uui-primary-50);
  --uui-control-border-hover: var(--uui-neutral-60);
  --uui-control-icon: var(--uui-neutral-50);
  --uui-control-icon-disabled: var(--uui-neutral-50);
  --uui-control-placeholder: var(--uui-neutral-50);
  --uui-control-placeholder-disabled: var(--uui-text-disabled);
  --uui-control-text: var(--uui-neutral-80);
  --uui-control-text-disabled: var(--uui-text-tertiary);
  --uui-icon: var(--uui-neutral-60);
  --uui-icon-active: var(--uui-neutral-80);
  --uui-icon-disabled: var(--uui-neutral-50);
  --uui-icon-hover: var(--uui-neutral-70);
  --uui-link: var(--uui-primary-50);
  --uui-link-hover: var(--uui-primary-60);
  --uui-link-visited: var(--purple-60);
  --uui-link-visited-hover: var(--purple-70);
  --uui-outline-focus: #009ECC7F;
  --uui-skeleton: var(--uui-neutral-40);
  --uui-text-bg-highlight: var(--uui-warning-10);
  --uui-text-critical: var(--uui-critical-70);
  --uui-text-disabled: var(--uui-neutral-50);
  --uui-text-info: var(--uui-info-70);
  --uui-text-primary: var(--uui-neutral-80);
  --uui-text-secondary: var(--uui-neutral-70);
  --uui-text-success: var(--uui-success-70);
  --uui-text-tertiary: var(--uui-neutral-60);
  --uui-text-warning: var(--uui-warning-70);
  --uui-font: "Source Sans Pro", sans-serif;
  --uui-font-mono: "Roboto Mono", monospace;
  /* Loveship old colors */
  --uui-color-night100-800: #D3D7E9;
  --uui-color-night100-900: #B1B9D8;
  --uui-color-night700-800: #30323D;
  --uui-color-night700-900: #1A1B20;
  /* end Loveship old colors */
  /* contrast colors */
  --uui-primary-contrast: var(--white);
  --uui-accent-contrast: var(--white);
  --uui-secondary-contrast: var(--white);
  --uui-critical-contrast: var(--white);
  --uui-info-contrast: var(--white);
  --uui-success-contrast: var(--white);
  --uui-warning-contrast: var(--night800);
  --uui-error-contrast: var(--white);
  /* shadows */
  --uui-shadow-level-1: 0 0 3px rgba(29, 30, 38, 0.15), 0 3px 6px rgba(29, 30, 38, 0.1);
  --uui-shadow-level-2: 0 6px 12px rgba(29, 30, 38, 0.1), 0 3px 12px rgba(29, 30, 38, 0.1), 0 0 6px rgba(29, 30, 38, 0.05);
  --uui-shadow-level-3: 0 0 10px rgba(29, 30, 38, 0.05), 0 6px 24px rgba(29, 30, 38, 0.12), 0 9px 15px rgba(29, 30, 38, 0.1);
  --uui-border-radius: 3px;
  /* core variables end */
  /* ------------------------------------ */
  /* begin color classes */
  /* Accordion */
  /* FileCard */
  /* Badge */
  /* Button */
  /* IconButton */
  /* LinkButton */
  /* Tooltip */
  /* CountIndicator */
  /* Status Indicator */
  /* FlexRow */
  /* Text */
  /* Tag */
  /* fonts */
  /* Typography */
}
.uui-theme-loveship .uui-color-accent,
.uui-theme-loveship .uui-color-grass,
.uui-theme-loveship .uui-color-success {
  --uui-color-5: var(--uui-accent-5);
  --uui-color-10: var(--uui-accent-10);
  --uui-color-20: var(--uui-accent-20);
  --uui-color-50: var(--uui-accent-50);
  --uui-color-60: var(--uui-accent-60);
  --uui-color-70: var(--uui-accent-70);
  --uui-color-contrast: var(--uui-accent-contrast);
}
.uui-theme-loveship .uui-color-primary,
.uui-theme-loveship .uui-color-sky,
.uui-theme-loveship .uui-color-info {
  --uui-color-5: var(--uui-primary-5);
  --uui-color-10: var(--uui-primary-10);
  --uui-color-20: var(--uui-primary-20);
  --uui-color-50: var(--uui-primary-50);
  --uui-color-60: var(--uui-primary-60);
  --uui-color-70: var(--uui-primary-70);
  --uui-color-contrast: var(--uui-primary-contrast);
}
.uui-theme-loveship .uui-color-fire,
.uui-theme-loveship .uui-color-critical,
.uui-theme-loveship .uui-color-error {
  --uui-color-5: var(--uui-critical-5);
  --uui-color-10: var(--uui-critical-10);
  --uui-color-20: var(--uui-critical-20);
  --uui-color-50: var(--uui-critical-50);
  --uui-color-60: var(--uui-critical-60);
  --uui-color-70: var(--uui-critical-70);
  --uui-color-contrast: var(--uui-critical-contrast);
}
.uui-theme-loveship .uui-color-white {
  --uui-color-5: color-mix(in srgb, #FFFFFF 15%, transparent);
  --uui-color-10: color-mix(in srgb, #FFFFFF 22%, transparent);
  --uui-color-20: color-mix(in srgb, #FFFFFF 29%, transparent);
  --uui-color-50: var(--uui-neutral-0);
  --uui-color-60: var(--uui-neutral-10);
  --uui-color-70: var(--uui-neutral-20);
  --uui-color-contrast: var(--uui-neutral-70);
}
.uui-theme-loveship .uui-color-night100 {
  --uui-color-5: var(--white);
  --uui-color-10: var(--white);
  --uui-color-20: var(--night50);
  --uui-color-50: var(--night100);
  --uui-color-60: var(--uui-color-night100-800);
  --uui-color-70: var(--uui-color-night100-900);
  --uui-color-contrast: var(--uui-neutral-70);
}
.uui-theme-loveship .uui-color-night300 {
  --uui-color-5: var(--night50);
  --uui-color-10: var(--night100);
  --uui-color-20: var(--night200);
  --uui-color-50: var(--night300);
  --uui-color-60: var(--night400);
  --uui-color-70: var(--night500);
  --uui-color-contrast: var(--uui-neutral-80);
}
.uui-theme-loveship .uui-color-night500 {
  --uui-color-5: var(--night200);
  --uui-color-10: var(--night300);
  --uui-color-20: var(--night400);
  --uui-color-50: var(--night500);
  --uui-color-60: var(--night600);
  --uui-color-70: var(--night700);
  --uui-color-contrast: var(--white);
}
.uui-theme-loveship .uui-color-night600,
.uui-theme-loveship .uui-color-neutral,
.uui-theme-loveship .uui-color-gray,
.uui-theme-loveship .uui-color-secondary {
  --uui-color-5: var(--uui-secondary-5);
  --uui-color-10: var(--uui-secondary-10);
  --uui-color-20: var(--uui-secondary-20);
  --uui-color-50: var(--uui-secondary-50);
  --uui-color-60: var(--uui-secondary-60);
  --uui-color-70: var(--uui-secondary-70);
  --uui-color-contrast: var(--uui-secondary-contrast);
}
.uui-theme-loveship .uui-color-night700 {
  --uui-color-5: var(--night400);
  --uui-color-10: var(--night500);
  --uui-color-20: var(--night600);
  --uui-color-50: var(--night700);
  --uui-color-60: var(--uui-color-night700-800);
  --uui-color-70: var(--uui-color-night700-900);
  --uui-color-contrast: var(--white);
}
.uui-theme-loveship .uui-color-warning,
.uui-theme-loveship .uui-color-sun {
  --uui-color-5: var(--uui-warning-5);
  --uui-color-10: var(--uui-warning-10);
  --uui-color-20: var(--uui-warning-20);
  --uui-color-50: var(--uui-warning-50);
  --uui-color-60: var(--uui-warning-60);
  --uui-color-70: var(--uui-warning-70);
  --uui-color-contrast: var(--uui-warning-contrast);
}
.uui-theme-loveship .uui-color-yellow {
  --uui-color-5: var(--yellow-5);
  --uui-color-10: var(--yellow-10);
  --uui-color-20: var(--yellow-20);
  --uui-color-50: var(--yellow-50);
  --uui-color-60: var(--yellow-60);
  --uui-color-70: var(--yellow-70);
  --uui-color-contrast: var(--uui-neutral-80);
}
.uui-theme-loveship .uui-color-orange {
  --uui-color-5: var(--orange-5);
  --uui-color-10: var(--orange-10);
  --uui-color-20: var(--orange-20);
  --uui-color-50: var(--orange-50);
  --uui-color-60: var(--orange-60);
  --uui-color-70: var(--orange-70);
  --uui-color-contrast: var(--uui-neutral-80);
}
.uui-theme-loveship .uui-color-fuchsia {
  --uui-color-5: var(--fuchsia-5);
  --uui-color-10: var(--fuchsia-10);
  --uui-color-20: var(--fuchsia-20);
  --uui-color-50: var(--fuchsia-50);
  --uui-color-60: var(--fuchsia-60);
  --uui-color-70: var(--fuchsia-70);
  --uui-color-contrast: var(--white);
}
.uui-theme-loveship .uui-color-purple {
  --uui-color-5: var(--purple-5);
  --uui-color-10: var(--purple-10);
  --uui-color-20: var(--purple-20);
  --uui-color-50: var(--purple-50);
  --uui-color-60: var(--purple-60);
  --uui-color-70: var(--purple-70);
  --uui-color-contrast: var(--white);
}
.uui-theme-loveship .uui-color-violet {
  --uui-color-5: var(--violet-5);
  --uui-color-10: var(--violet-10);
  --uui-color-20: var(--violet-20);
  --uui-color-50: var(--violet-50);
  --uui-color-60: var(--violet-60);
  --uui-color-70: var(--violet-70);
  --uui-color-contrast: var(--white);
}
.uui-theme-loveship .uui-color-cobalt {
  --uui-color-5: var(--cobalt-5);
  --uui-color-10: var(--cobalt-10);
  --uui-color-20: var(--cobalt-20);
  --uui-color-50: var(--cobalt-50);
  --uui-color-60: var(--cobalt-60);
  --uui-color-70: var(--cobalt-70);
  --uui-color-contrast: var(--white);
}
.uui-theme-loveship .uui-color-cyan {
  --uui-color-5: var(--cyan-5);
  --uui-color-10: var(--cyan-10);
  --uui-color-20: var(--cyan-20);
  --uui-color-50: var(--cyan-50);
  --uui-color-60: var(--cyan-60);
  --uui-color-70: var(--cyan-70);
  --uui-color-contrast: var(--uui-neutral-80);
}
.uui-theme-loveship .uui-color-mint {
  --uui-color-5: var(--mint-5);
  --uui-color-10: var(--mint-10);
  --uui-color-20: var(--mint-20);
  --uui-color-50: var(--mint-50);
  --uui-color-60: var(--mint-60);
  --uui-color-70: var(--mint-70);
  --uui-color-contrast: var(--uui-neutral-80);
}
.uui-theme-loveship .uui-accordion-container {
  --uui-accordion-border-radius: 6px;
}
.uui-theme-loveship .uui-file_card {
  --uui-file_card-border-radius: 6px;
}
.uui-theme-loveship .uui-badge.uui-color-white.uui-fill-solid {
  --uui-badge-border-hover: var(--uui-neutral-30);
}
.uui-theme-loveship .uui-badge.uui-color-white.uui-fill-outline {
  --uui-badge-bg: var(--uui-neutral-0);
  --uui-badge-bg-hover: var(--uui-neutral-30);
  --uui-badge-border: var(--uui-neutral-30);
  --uui-badge-border-hover: var(--uui-neutral-30);
  --uui-badge-caption: var(--uui-neutral-70);
  --uui-badge-caption-hover: var(--uui-neutral-70);
}
.uui-theme-loveship .uui-badge.uui-color-neutral.uui-fill-solid,
.uui-theme-loveship .uui-badge.uui-color-night300.uui-fill-solid {
  --uui-badge-bg: var(--uui-neutral-30);
  --uui-badge-bg-hover: var(--uui-neutral-40);
  --uui-badge-border: var(--uui-neutral-30);
  --uui-badge-border-hover: var(--uui-neutral-40);
  --uui-color-contrast: var(--uui-neutral-80);
}
.uui-theme-loveship .uui-badge.uui-color-neutral.uui-fill-solid .uui-count_indicator,
.uui-theme-loveship .uui-badge.uui-color-night300.uui-fill-solid .uui-count_indicator {
  --uui-count_indicator-bg: var(--uui-neutral-0);
  --uui-count_indicator-border: var(--uui-neutral-0);
}
.uui-theme-loveship .uui-badge.uui-color-neutral.uui-fill-outline,
.uui-theme-loveship .uui-badge.uui-color-night300.uui-fill-outline {
  --uui-badge-bg: var(--uui-neutral-20);
  --uui-badge-bg-hover: var(--uui-neutral-30);
  --uui-badge-border: var(--uui-neutral-40);
  --uui-badge-caption: var(--uui-neutral-80);
  --uui-badge-caption-hover: var(--uui-neutral-80);
}
.uui-theme-loveship .uui-badge.uui-color-night100.uui-fill-solid {
  --uui-badge-bg-hover: var(--uui-neutral-20);
  --uui-badge-border-hover: var(--uui-neutral-20);
}
.uui-theme-loveship .uui-badge.uui-color-night100.uui-fill-outline {
  --uui-badge-bg: var(--uui-neutral-10);
  --uui-badge-bg-hover: var(--uui-neutral-20);
  --uui-badge-border: var(--uui-neutral-30);
  --uui-badge-border-hover: var(--uui-neutral-30);
  --uui-badge-caption: var(--uui-neutral-70);
  --uui-badge-caption-hover: var(--uui-neutral-70);
}
.uui-theme-loveship .uui-badge.uui-color-night100.uui-fill-outline .uui-count_indicator {
  --uui-count_indicator-bg: color-mix(in srgb, var(--uui-badge-caption) 8%, transparent);
  --uui-count_indicator-border: color-mix(in srgb, var(--uui-badge-caption) 40%, transparent);
}
.uui-theme-loveship .uui-badge.uui-color-night600.uui-fill-outline {
  --uui-badge-bg: var(--uui-neutral-20);
  --uui-badge-border: var(--uui-neutral-60);
  --uui-badge-border-hover: var(--uui-neutral-60);
  --uui-badge-caption: var(--uui-neutral-80);
  --uui-badge-caption-hover: var(--uui-neutral-80);
}
.uui-theme-loveship .uui-badge.uui-color-yellow.uui-fill-outline {
  --uui-badge-border: var(--yellow-20);
}
.uui-theme-loveship .uui-button:is(.uui-color-secondary, .uui-color-neutral, .uui-color-gray, .uui-color-night600):is(.uui-fill-outline, .uui-fill-none, .uui-fill-ghost) {
  --uui-btn-caption: var(--uui-neutral-70);
}
.uui-theme-loveship .uui-icon_button.uui-color-secondary {
  --uui-icon_btn: var(--uui-neutral-50);
  --uui-icon_btn-hover: var(--uui-neutral-60);
  --uui-icon_btn-active: var(--uui-neutral-70);
}
.uui-theme-loveship .uui-icon_button.uui-color-white {
  --uui-icon_btn-hover: var(--uui-neutral-20);
  --uui-icon_btn-active: var(--uui-neutral-30);
}
.uui-theme-loveship .uui-link_button.uui-color-contrast {
  --uui-link_btn-text: var(--uui-neutral-5);
  --uui-link_btn-text-hover: var(--uui-neutral-10);
  --uui-link_btn-text-active: var(--uui-neutral-20);
}
.uui-theme-loveship .uui-link_button.uui-color-white {
  --uui-link_btn-text: var(--uui-neutral-5);
  --uui-link_btn-text-hover: var(--uui-neutral-30);
  --uui-link_btn-text-active: var(--uui-neutral-40);
  --uui-link_btn-text-disabled: color-mix(in srgb, var(--white) 40%, transparent);
}
.uui-theme-loveship .uui-tooltip-container.uui-color-white {
  --uui-tooltip-bg: var(--uui-neutral-0);
  --uui-tooltip-text: var(--uui-neutral-80);
}
.uui-theme-loveship .uui-tooltip-container.uui-color-red {
  --uui-tooltip-bg: var(--uui-error-60);
}
.uui-theme-loveship .uui-tooltip-container.uui-color-gray {
  --uui-tooltip-bg: var(--uui-neutral-80);
  --uui-tooltip-text: var(--uui-neutral-5);
}
.uui-theme-loveship .uui-count_indicator.uui-color-gray,
.uui-theme-loveship .uui-count_indicator.uui-color-neutral {
  --uui-count_indicator-bg: var(--uui-neutral-30);
  --uui-count_indicator-border: var(--uui-neutral-30);
}
.uui-theme-loveship .uui-count_indicator:is(.uui-color-gray, .uui-color-neutral, .uui-color-white, .uui-color-sun, .uui-color-warning) {
  --uui-count_indicator-caption: var(--uui-neutral-70);
}
.uui-theme-loveship .uui-status_indicator.uui-color-white.uui-fill-outline .uui-status_indicator_dot {
  background-color: color-mix(in srgb, var(--white) 20%, transparent);
  border: 1px solid var(--uui-neutral-40);
}
.uui-theme-loveship .uui-flex-row.uui-color-none {
  --uui-flex-row-bg: transparent;
}
.uui-theme-loveship .uui-flex-row.uui-color-white {
  --uui-flex-row-bg: var(--white);
}
.uui-theme-loveship .uui-flex-row.uui-color-night50 {
  --uui-flex-row-bg: var(--night50);
}
.uui-theme-loveship .uui-flex-row.uui-color-night100 {
  --uui-flex-row-bg: var(--night100);
}
.uui-theme-loveship .uui-panel.uui-color-white {
  --uui-panel-bg: var(--white);
}
.uui-theme-loveship .uui-panel.uui-color-night50 {
  --uui-panel-bg: var(--night50);
}
.uui-theme-loveship .uui-text.uui-color-night50 {
  --uui-text: var(--night50);
}
.uui-theme-loveship .uui-text.uui-color-night400 {
  --uui-text: var(--night400);
}
.uui-theme-loveship .uui-text.uui-color-night800 {
  --uui-text: var(--night800);
}
.uui-theme-loveship .uui-tag.uui-color-white.uui-fill-solid {
  --uui-tag-bg-hover: var(--uui-neutral-10);
  --uui-tag-border-hover: var(--uui-neutral-10);
}
.uui-theme-loveship .uui-tag.uui-color-white.uui-fill-solid .uui-count_indicator {
  --uui-count_indicator-bg: var(--uui-neutral-30);
  --uui-count_indicator-border: var(--uui-neutral-30);
}
.uui-theme-loveship .uui-tag.uui-color-white.uui-fill-outline {
  --uui-tag-bg: var(--uui-neutral-0);
  --uui-tag-border: var(--uui-neutral-30);
  --uui-tag-bg-hover: var(--uui-neutral-10);
  --uui-tag-border-hover: var(--uui-neutral-30);
}
.uui-theme-loveship .uui-tag.uui-color-white.uui-fill-outline .uui-count_indicator {
  --uui-count_indicator-bg: var(--uui-neutral-30);
  --uui-count_indicator-border: var(--uui-neutral-30);
}
.uui-theme-loveship .uui-tag.uui-color-night100.uui-fill-solid {
  --uui-tag-bg-hover: var(--uui-neutral-20);
  --uui-tag-border-hover: var(--uui-neutral-20);
}
.uui-theme-loveship .uui-tag.uui-color-night100.uui-fill-solid .uui-count_indicator {
  --uui-count_indicator-bg: var(--uui-neutral-30);
  --uui-count_indicator-border: var(--uui-neutral-30);
}
.uui-theme-loveship .uui-tag.uui-color-night100.uui-fill-outline {
  --uui-tag-bg: var(--uui-neutral-5);
  --uui-tag-bg-hover: var(--uui-neutral-10);
  --uui-tag-border: var(--uui-neutral-30);
  --uui-tag-border-hover: var(--uui-neutral-30);
}
.uui-theme-loveship .uui-tag.uui-color-night100.uui-fill-outline .uui-count_indicator {
  --uui-count_indicator-bg: var(--uui-neutral-30);
  --uui-count_indicator-border: var(--uui-neutral-30);
}
.uui-theme-loveship .uui-tag.uui-color-neutral.uui-fill-solid, .uui-theme-loveship .uui-tag.uui-color-night300.uui-fill-solid {
  --uui-tag-bg: var(--uui-neutral-30);
  --uui-tag-bg-hover: var(--uui-neutral-40);
  --uui-tag-border: var(--uui-neutral-30);
  --uui-tag-border-hover: var(--uui-neutral-40);
  --uui-tag-caption: var(--uui-neutral-70);
  --uui-tag-fill: var(--uui-neutral-70);
}
.uui-theme-loveship .uui-tag.uui-color-neutral.uui-fill-solid .uui-count_indicator, .uui-theme-loveship .uui-tag.uui-color-night300.uui-fill-solid .uui-count_indicator {
  --uui-count_indicator-bg: var(--uui-neutral-0);
  --uui-count_indicator-border: var(--uui-neutral-0);
  --uui-count_indicator-caption: var(--uui-neutral-70);
}
.uui-theme-loveship .uui-tag.uui-color-neutral.uui-fill-outline, .uui-theme-loveship .uui-tag.uui-color-night300.uui-fill-outline {
  --uui-tag-bg: var(--uui-neutral-20);
  --uui-tag-bg-hover: var(--uui-neutral-30);
  --uui-tag-border: var(--uui-neutral-40);
  --uui-tag-border-hover: var(--uui-neutral-40);
}
.uui-theme-loveship .uui-tag.uui-color-neutral.uui-fill-outline .uui-count_indicator, .uui-theme-loveship .uui-tag.uui-color-night300.uui-fill-outline .uui-count_indicator {
  --uui-count_indicator-bg: var(--uui-neutral-30);
  --uui-count_indicator-border: var(--uui-neutral-30);
}
.uui-theme-loveship .uui-tag.uui-color-night700.uui-fill-solid {
  --uui-tag-caption: var(--uui-neutral-0);
  --uui-tag-fill: var(--uui-neutral-0);
  --uui-tag-bg: var(--uui-neutral-70);
  --uui-tag-border: var(--uui-neutral-70);
  --uui-tag-bg-hover: var(--uui-neutral-80);
  --uui-tag-border-hover: var(--uui-neutral-80);
}
.uui-theme-loveship .uui-tag.uui-color-night700.uui-fill-solid .uui-count_indicator {
  --uui-count_indicator-border: color-mix(in srgb, var(--uui-neutral-0) 50%, transparent);
  --uui-count_indicator-bg: color-mix(in srgb, var(--uui-neutral-0) 10%, transparent);
}
.uui-theme-loveship .uui-tag.uui-color-night700.uui-fill-outline {
  --uui-tag-bg: var(--uui-neutral-20);
  --uui-tag-bg-hover: var(--uui-neutral-30);
  --uui-tag-border: var(--uui-neutral-50);
  --uui-tag-border-hover: var(--uui-neutral-50);
}
.uui-theme-loveship .uui-tag.uui-color-warning.uui-fill-solid, .uui-theme-loveship .uui-tag.uui-color-sun.uui-fill-solid {
  --uui-tag-caption: var(--uui-text-primary);
  --uui-tag-fill: var(--uui-neutral-90);
}
.uui-theme-loveship .uui-tag.uui-color-warning.uui-fill-solid .uui-count_indicator, .uui-theme-loveship .uui-tag.uui-color-sun.uui-fill-solid .uui-count_indicator {
  --uui-count_indicator-bg: color-mix(in srgb, var(--uui-neutral-90) 5%, transparent);
  --uui-count_indicator-border: color-mix(in srgb, var(--uui-neutral-90) 30%, transparent);
}
.uui-theme-loveship .uui-tag.uui-color-warning.uui-fill-outline .uui-count_indicator, .uui-theme-loveship .uui-tag.uui-color-sun.uui-fill-outline .uui-count_indicator {
  --uui-count_indicator-bg: var(--uui-neutral-0);
  --uui-count_indicator-border: var(--uui-neutral-40);
}
.uui-theme-loveship .uui-font-sans {
  --uui-text-font: var(--uui-font);
  --uui-text-font-weight: 400;
}
.uui-theme-loveship .uui-font-sans-semibold {
  --uui-text-font: var(--uui-font);
  --uui-text-font-weight: 600;
}
.uui-theme-loveship .uui-font-sans-light {
  --uui-text-font: var(--uui-font);
  --uui-text-font-weight: 300;
}
.uui-theme-loveship .uui-typography h1 {
  font-weight: 600;
}
.uui-theme-loveship .uui-typography h2 {
  font-weight: 700;
}
.uui-theme-loveship .uui-typography h3 {
  font-weight: 600;
}
.uui-theme-loveship .uui-typography .hero-header {
  font-size: 72px;
  line-height: 72px;
  font-weight: 700;
}