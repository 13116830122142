:global(.uui-dr_addons-checkbox) {
  padding-right: 12px;
}

.drag-handle {
  position: absolute;
  left: 2px;
  width: 6px;
  top: 3px;
  bottom: 2px;
  align-self: stretch;
  visibility: hidden;
}
:global(.-draggable.uui-table-row):not(:global(.uui-drop-accepted)):hover .drag-handle {
  visibility: visible;
}

:global(.uui-dr_addons-indent) {
  display: flex;
  justify-content: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 12px;
  box-sizing: content-box;
}

:global(.uui-folding-arrow) {
  margin: auto 0;
}
:global(.uui-folding-arrow):global(.uui-icon) {
  position: relative;
}

.icon-container {
  fill: var(--uui-icon);
}