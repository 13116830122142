.uui-typography {
  font-family: var(--uui-font);
  color: var(--uui-text-primary);
}
.uui-typography .hero-header, .uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin: 0;
}
.uui-typography .hero-header {
  font-family: var(--uui-font);
  font-weight: 500;
  margin: 30px 0;
  font-size: 66px;
  line-height: 72px;
}
.uui-typography .promo-header, .uui-typography h1, .uui-typography h2, .uui-typography h3 {
  font-family: var(--uui-font);
  font-weight: 700;
}
.uui-typography h4, .uui-typography h5, .uui-typography h6 {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.uui-typography h1 {
  font-size: 42px;
}
.uui-typography h2 {
  font-size: 30px;
  letter-spacing: 0.1px;
}
.uui-typography h2 .promo-header {
  letter-spacing: 2px;
}
.uui-typography h3 {
  font-size: 24px;
}
.uui-typography h4 {
  font-size: 18px;
  letter-spacing: 0.1px;
}
.uui-typography h5 {
  font-size: 16px;
}
.uui-typography h6 {
  font-size: 14px;
}
.uui-typography b, .uui-typography strong {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography i, .uui-typography em {
  font-family: var(--uui-font);
  font-weight: 400;
  font-style: italic;
}
.uui-typography a {
  display: inline-flex;
  align-items: center;
  color: var(--uui-link);
  text-decoration: underline;
}
.uui-typography a:visited {
  color: var(--uui-link-visited);
}
.uui-typography a:visited:hover {
  color: var(--uui-link-visited-hover);
}
.uui-typography a:hover {
  color: var(--uui-link-hover);
}
.uui-typography code {
  padding: 0.125em 0.25em;
  color: var(--uui-text-primary);
  background-color: var(--uui-neutral-30);
  font-family: var(--uui-font-mono, monospace);
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography ol, .uui-typography ul {
  padding: 0;
}
.uui-typography li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.uui-typography pre {
  padding: 1.25em;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography img {
  max-width: 100%;
}
.uui-typography figure {
  margin: 0;
}
.uui-typography figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.uui-typography p {
  margin: 0.5em 0;
  line-height: 1.5;
}
.uui-typography .uui-critical {
  color: var(--uui-text-critical);
}
.uui-typography .uui-info {
  color: var(--uui-text-info);
}
.uui-typography .uui-success {
  color: var(--uui-text-success);
}
.uui-typography .uui-warning {
  color: var(--uui-text-warning);
}
.uui-typography .uui-highlight {
  background-color: var(--uui-warning-10);
}

.uui-typography-size-12 {
  font-size: 12px;
}

.uui-typography-size-14 {
  font-size: 14px;
}

.uui-typography-size-16 {
  font-size: 16px;
}

.root {
  --uui-picker_toggler-bg: var(--uui-control-bg);
  --uui-picker_toggler-border: var(--uui-control-border);
  --uui-picker_toggler-border-hover: var(--uui-control-border-hover);
  --uui-picker_toggler-border-active: var(--uui-control-border-focus);
  --uui-picker_toggler-border-error: var(--uui-error-50);
  --uui-picker_toggler-placeholder: var(--uui-control-placeholder);
  --uui-picker_toggler-placeholder-disabled: var(--uui-control-placeholder-disabled);
  --uui-picker_toggler-placeholder-readonly: var(--uui-control-placeholder-disabled);
  --uui-picker_toggler-text: var(--uui-control-text);
  --uui-picker_toggler-text-disabled: var(--uui-control-text-disabled);
  --uui-picker_toggler-icon: var(--uui-control-icon);
  --uui-picker_toggler-icon-disabled: var(--uui-control-icon-disabled);
  --uui-picker_toggler-icon-clickable: var(--uui-icon);
  --uui-picker_toggler-icon-clickable-hover: var(--uui-icon-hover);
  --uui-picker_toggler-border-radius: var(--uui-border-radius);
  --uui-picker_toggler-font: var(--uui-font);
  --uui-picker_toggler-font-weight: 400;
  padding-top: 2px;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
  box-sizing: border-box;
  border-style: solid;
  border-radius: var(--uui-picker_toggler-border-radius);
}
.root.mode-form {
  background-color: var(--uui-picker_toggler-bg);
  border-color: var(--uui-picker_toggler-border);
  fill: var(--uui-picker_toggler-icon);
}
.root.mode-form :global(.uui-icon):global(.-clickable):global(.uui-enabled), .root.mode-form :global(.uui-icon-dropdown):global(.uui-enabled) {
  fill: var(--uui-picker_toggler-icon-clickable);
}
.root.mode-form :global(.uui-icon):global(.-clickable):global(.uui-enabled):hover, .root.mode-form :global(.uui-icon-dropdown):global(.uui-enabled):hover {
  fill: var(--uui-picker_toggler-icon-clickable-hover);
}
.root.mode-form :global(.uui-icon):global(.-clickable):global(.uui-disabled), .root.mode-form :global(.uui-icon-dropdown):global(.uui-disabled) {
  fill: var(--uui-picker_toggler-icon-disabled);
}
.root.mode-form:hover {
  border-color: var(--uui-picker_toggler-border-hover);
}
.root.mode-form:global(.uui-focus) {
  border-color: var(--uui-picker_toggler-border-active);
  outline: none;
}
.root.mode-cell {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0;
  fill: var(--uui-picker_toggler-icon);
}
.root.mode-cell :global(.uui-icon):global(.-clickable), .root.mode-cell :global(.uui-icon-dropdown) {
  cursor: pointer;
}
.root.mode-cell :global(.uui-icon):global(.-clickable):global(.uui-enabled), .root.mode-cell :global(.uui-icon-dropdown):global(.uui-enabled) {
  fill: transparent;
}
.root.mode-cell :global(.uui-icon):global(.-clickable):global(.uui-disabled), .root.mode-cell :global(.uui-icon-dropdown):global(.uui-disabled) {
  fill: transparent;
}
.root.mode-cell:hover :global(.uui-icon):global(.-clickable):global(.uui-enabled), .root.mode-cell:hover :global(.uui-icon-dropdown):global(.uui-enabled), .root.mode-cell:global(.uui-focus) :global(.uui-icon):global(.-clickable):global(.uui-enabled), .root.mode-cell:global(.uui-focus) :global(.uui-icon-dropdown):global(.uui-enabled) {
  fill: var(--uui-picker_toggler-icon-clickable);
}
.root.mode-cell:hover :global(.uui-icon):global(.-clickable):global(.uui-enabled):hover, .root.mode-cell:hover :global(.uui-icon-dropdown):global(.uui-enabled):hover, .root.mode-cell:global(.uui-focus) :global(.uui-icon):global(.-clickable):global(.uui-enabled):hover, .root.mode-cell:global(.uui-focus) :global(.uui-icon-dropdown):global(.uui-enabled):hover {
  fill: var(--uui-picker_toggler-icon-clickable-hover);
}
.root.mode-cell:hover :global(.uui-icon):global(.-clickable):global(.uui-disabled), .root.mode-cell:hover :global(.uui-icon-dropdown):global(.uui-disabled), .root.mode-cell:global(.uui-focus) :global(.uui-icon):global(.-clickable):global(.uui-disabled), .root.mode-cell:global(.uui-focus) :global(.uui-icon-dropdown):global(.uui-disabled) {
  fill: transparent;
}
.root.mode-cell:global(.uui-focus) {
  outline: none;
}
.root.mode-inline {
  background-color: initial;
  border-color: transparent;
  fill: var(--uui-picker_toggler-icon-clickable);
}
.root.mode-inline:hover {
  border-color: var(--uui-picker_toggler-border-hover);
}
.root.mode-inline:global(.uui-focus) {
  border-color: var(--uui-picker_toggler-border-active);
  outline: none;
}
.root:global(.uui-invalid).mode-form {
  border-color: var(--uui-picker_toggler-border-error);
}
.root:global(.uui-readonly).mode-form {
  background-color: var(--uui-control-bg-disabled);
  border-color: var(--uui-control-border-disabled);
  pointer-events: none;
}
.root:global(.uui-readonly).mode-form:hover {
  border-color: var(--uui-control-border-disabled);
}
.root:global(.uui-readonly).mode-form :global(.uui-input)::placeholder {
  color: var(--uui-control-placeholder-disabled);
}
.root:global(.uui-readonly).mode-cell {
  background-color: transparent;
  border-color: transparent;
  pointer-events: none;
}
.root:global(.uui-readonly).mode-cell:hover {
  background-color: transparent;
  border-color: transparent;
}
.root:global(.uui-readonly).mode-cell :global(.uui-input)::placeholder {
  color: var(--uui-control-placeholder-disabled);
}
.root:global(.uui-readonly) :global(.uui-icon-dropdown), .root:global(.uui-readonly) :global(.uui-icon-cancel) {
  visibility: hidden;
}
.root:global(.uui-disabled) {
  fill: var(--uui-picker_toggler-icon-disabled);
}
.root:global(.uui-disabled).mode-form {
  background-color: var(--uui-control-bg-disabled);
  color: var(--uui-control-text-disabled);
  fill: var(--uui-control-text-disabled);
  border-color: var(--uui-control-border-disabled);
  cursor: default;
  pointer-events: none;
}
.root:global(.uui-disabled).mode-form :global(.uui-input) {
  color: var(--uui-control-text-disabled);
}
.root:global(.uui-disabled).mode-form :global(.uui-input)::placeholder {
  color: var(--uui-control-placeholder-disabled);
}
.root:global(.uui-disabled).mode-form:hover, .root:global(.uui-disabled).mode-form:active, .root:global(.uui-disabled).mode-form:focus {
  background-color: var(--uui-control-bg-disabled);
  color: var(--uui-control-text-disabled);
  fill: var(--uui-control-text-disabled);
  border-color: var(--uui-control-border-disabled);
  box-shadow: none;
}
.root:global(.uui-disabled).mode-form:hover :global(.uui-input), .root:global(.uui-disabled).mode-form:active :global(.uui-input), .root:global(.uui-disabled).mode-form:focus :global(.uui-input) {
  color: var(--uui-control-text-disabled);
}
.root:global(.uui-disabled).mode-form:hover :global(.uui-input)::placeholder, .root:global(.uui-disabled).mode-form:active :global(.uui-input)::placeholder, .root:global(.uui-disabled).mode-form:focus :global(.uui-input)::placeholder {
  color: var(--uui-control-placeholder-disabled);
}
.root:global(.uui-disabled).mode-cell {
  background-color: transparent;
  color: var(--uui-control-text-disabled);
  fill: var(--uui-control-text-disabled);
  border-color: transparent;
  cursor: default;
  pointer-events: none;
}
.root:global(.uui-disabled).mode-cell :global(.uui-input) {
  color: var(--uui-control-text-disabled);
}
.root:global(.uui-disabled).mode-cell :global(.uui-input)::placeholder {
  color: var(--uui-control-placeholder-disabled);
}
.root:global(.uui-disabled).mode-cell:hover, .root:global(.uui-disabled).mode-cell:active, .root:global(.uui-disabled).mode-cell:focus {
  background-color: transparent;
  color: var(--uui-control-text-disabled);
  fill: var(--uui-control-text-disabled);
  border-color: transparent;
  box-shadow: none;
}
.root:global(.uui-disabled).mode-cell:hover :global(.uui-input), .root:global(.uui-disabled).mode-cell:active :global(.uui-input), .root:global(.uui-disabled).mode-cell:focus :global(.uui-input) {
  color: var(--uui-control-text-disabled);
}
.root:global(.uui-disabled).mode-cell:hover :global(.uui-input)::placeholder, .root:global(.uui-disabled).mode-cell:active :global(.uui-input)::placeholder, .root:global(.uui-disabled).mode-cell:focus :global(.uui-input)::placeholder {
  color: var(--uui-control-placeholder-disabled);
}
.root:global(.uui-disabled) :global(.uui-input) {
  cursor: default;
}
.root:global(.uui-disabled) :global(.uui-icon-dropdown), .root:global(.uui-disabled) :global(.uui-icon-cancel) {
  visibility: hidden;
}
.root :global(.uui-input) {
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 2px;
  font-family: var(--uui-picker_toggler-font);
  font-weight: var(--uui-picker_toggler-font-weight, normal);
  color: var(--uui-picker_toggler-text);
}
.root :global(.uui-input)::placeholder {
  color: var(--uui-picker_toggler-placeholder);
}
.root :global(.uui-input)::-moz-placeholder {
  opacity: 1;
}
.root :global(.uui-caption) {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--uui-picker_toggler-font);
  font-weight: var(--uui-picker_toggler-font-weight, normal);
  align-self: center;
  white-space: nowrap;
}
.root :global(.uui-button-box) {
  margin-right: 3px;
  margin-bottom: 2px;
}
.root :global(.uui-button-box):last-of-type {
  margin-right: 0;
}
.root :global(.uui-placeholder) {
  color: var(--uui-picker_toggler-text);
}
.root :global(.uui-placeholder)::placeholder {
  color: var(--uui-picker_toggler-text);
}
.root :global(.uui-placeholder)::-moz-placeholder {
  opacity: 1;
}
.root :global(.uui-icon-cancel) {
  margin-left: 6px;
}
.root.size-24 {
  min-width: 24px;
}
.root.size-24:global(.uui-input-box) {
  min-height: 24px;
  border-width: 1px;
}
.root.size-24 :global(.uui-input) {
  line-height: 18px;
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-top: 0;
  padding-bottom: 0;
}
.root.size-24 :not(:global(.uui-button-box)) > :global(.uui-icon) {
  height: 18px;
  margin-bottom: 2px;
}
.root.size-24 :global(.uui-icon) svg {
  height: 12px;
  width: inherit;
}
.root.size-30 {
  min-width: 30px;
}
.root.size-30:global(.uui-input-box) {
  min-height: 30px;
  border-width: 1px;
}
.root.size-30 :global(.uui-input) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.root.size-30 :not(:global(.uui-button-box)) > :global(.uui-icon) {
  height: 24px;
  margin-bottom: 2px;
}
.root.size-30 :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.root.size-36 {
  min-width: 36px;
}
.root.size-36:global(.uui-input-box) {
  min-height: 36px;
  border-width: 1px;
}
.root.size-36 :global(.uui-input) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.root.size-36 :not(:global(.uui-button-box)) > :global(.uui-icon) {
  height: 30px;
  margin-bottom: 2px;
}
.root.size-36 :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.root.size-42 {
  min-width: 42px;
}
.root.size-42:global(.uui-input-box) {
  min-height: 42px;
  border-width: 1px;
}
.root.size-42 :global(.uui-input) {
  line-height: 24px;
  font-size: 16px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.root.size-42 :not(:global(.uui-button-box)) > :global(.uui-icon) {
  height: 36px;
  margin-bottom: 2px;
}
.root.size-42 :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.root.size-48 {
  min-width: 48px;
}
.root.size-48:global(.uui-input-box) {
  min-height: 48px;
  border-width: 1px;
}
.root.size-48 :global(.uui-input) {
  line-height: 24px;
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-top: 9px;
  padding-bottom: 9px;
}
.root.size-48 :not(:global(.uui-button-box)) > :global(.uui-icon) {
  height: 42px;
  margin-bottom: 2px;
}
.root.size-48 :global(.uui-icon) svg {
  height: 24px;
  width: inherit;
}