.container {
  height: 80vh;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  width: 100% !important;
}

.disabled > div {
  background-color: var(--night100) !important;
  opacity: 0.5 !important;
}
