.container {
  display: flex;
  align-items: center;
}

.base-rating-container {
  margin: 0 8px 0 8px;
}

.scale {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
}
.scale.size-18 {
  width: 152px;
  height: 16px;
}
.scale.size-18 .scale-icon {
  width: 152px;
}
.scale.size-24 {
  width: 232px;
  height: 22px;
}
.scale.size-24 .scale-icon {
  width: 232px;
}

.short-scale.size-18 {
  width: 115px;
}
.short-scale.size-24 {
  width: 176px;
}

.scale-icon:global(.uui-icon) {
  flex-direction: row;
}
.scale-icon svg {
  flex-grow: 1;
  height: auto;
}

.handler {
  position: absolute;
  top: 0;
  left: 0;
}
.handler.size-18 {
  width: 16px;
  height: 16px;
}
.handler.size-24 {
  width: 22px;
  height: 22px;
}
.handler.size-24 .handlerIcon svg {
  width: 22px;
  height: 22px;
}
.handler.hidden {
  visibility: hidden;
}

.na-icon-container {
  margin-left: 18px;
}

.na-icon {
  cursor: pointer;
  fill: #D9DBE3;
}
.na-icon:hover {
  fill: #C0C3CE;
}
.na-icon.checked {
  fill: #6C7283;
}
.na-icon.disabled {
  fill: #D9DBE3;
  cursor: default;
}
.na-icon.size-18 {
  width: 18px;
  height: 18px;
}
.na-icon.size-24 {
  width: 24px;
  height: 24px;
}
.na-icon.size-24 svg {
  width: 24px;
  height: 24px;
}

.tooltipsBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
}

.tooltipsBoxItem {
  position: fixed;
  width: 1px;
}

.tooltip {
  pointer-events: none;
}