/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 300" instead "$font-sans-light"!
 */
/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 400" instead "$font-sans"!
 */
/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 600" instead "$font-sans-semibold"!
 */
/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 700" instead "$font-sans-bold"!
 */
/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 400" & "font-style: italic" instead "$font-sans-italic"!
 */
/* Night scale */
.root {
  fill: #FCAA00;
}
.root :global(.uui-rating-star) {
  padding: 0 1px;
}
.root :global(.uui-rating-filled-star-container) svg {
  stroke-width: 0.1px;
}
.root :global(.uui-rating-filled-star-container) svg {
  stroke: #FCAA00;
}
.root.size-18 svg {
  width: 18px;
  height: 18px;
}
.root.size-24 svg {
  width: 24px;
  height: 24px;
}
.root.size-30 svg {
  width: 30px;
  height: 30px;
}
.root:global(.uui-disabled) :global(.uui-rating-star) {
  fill: #E1E3EB;
  cursor: default;
}

.tooltip {
  pointer-events: none;
}