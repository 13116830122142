.uui-typography {
  font-family: var(--uui-font);
  color: var(--uui-text-primary);
}
.uui-typography .hero-header, .uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin: 0;
}
.uui-typography .hero-header {
  font-family: var(--uui-font);
  font-weight: 500;
  margin: 30px 0;
  font-size: 66px;
  line-height: 72px;
}
.uui-typography .promo-header, .uui-typography h1, .uui-typography h2, .uui-typography h3 {
  font-family: var(--uui-font);
  font-weight: 700;
}
.uui-typography h4, .uui-typography h5, .uui-typography h6 {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.uui-typography h1 {
  font-size: 42px;
}
.uui-typography h2 {
  font-size: 30px;
  letter-spacing: 0.1px;
}
.uui-typography h2 .promo-header {
  letter-spacing: 2px;
}
.uui-typography h3 {
  font-size: 24px;
}
.uui-typography h4 {
  font-size: 18px;
  letter-spacing: 0.1px;
}
.uui-typography h5 {
  font-size: 16px;
}
.uui-typography h6 {
  font-size: 14px;
}
.uui-typography b, .uui-typography strong {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography i, .uui-typography em {
  font-family: var(--uui-font);
  font-weight: 400;
  font-style: italic;
}
.uui-typography a {
  display: inline-flex;
  align-items: center;
  color: var(--uui-link);
  text-decoration: underline;
}
.uui-typography a:visited {
  color: var(--uui-link-visited);
}
.uui-typography a:visited:hover {
  color: var(--uui-link-visited-hover);
}
.uui-typography a:hover {
  color: var(--uui-link-hover);
}
.uui-typography code {
  padding: 0.125em 0.25em;
  color: var(--uui-text-primary);
  background-color: var(--uui-neutral-30);
  font-family: var(--uui-font-mono, monospace);
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography ol, .uui-typography ul {
  padding: 0;
}
.uui-typography li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.uui-typography pre {
  padding: 1.25em;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography img {
  max-width: 100%;
}
.uui-typography figure {
  margin: 0;
}
.uui-typography figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.uui-typography p {
  margin: 0.5em 0;
  line-height: 1.5;
}
.uui-typography .uui-critical {
  color: var(--uui-text-critical);
}
.uui-typography .uui-info {
  color: var(--uui-text-info);
}
.uui-typography .uui-success {
  color: var(--uui-text-success);
}
.uui-typography .uui-warning {
  color: var(--uui-text-warning);
}
.uui-typography .uui-highlight {
  background-color: var(--uui-warning-10);
}

.uui-typography-size-12 {
  font-size: 12px;
}

.uui-typography-size-14 {
  font-size: 14px;
}

.uui-typography-size-16 {
  font-size: 16px;
}

.root {
  --uui-link_btn-text: var(--uui-color-50);
  --uui-link_btn-text-hover: var(--uui-color-60);
  --uui-link_btn-text-active: var(--uui-color-70);
  --uui-link_btn-text-disabled: var(--uui-text-disabled);
  --uui-link-button-font: var(--uui-font);
  --uui-link-button-font-weight: 600;
  text-decoration: none;
  outline: none;
  border: 0;
  background: none;
  box-sizing: border-box;
  appearance: none;
  align-items: unset;
  padding: unset;
  margin: unset;
  justify-content: unset;
  overflow: hidden;
  display: flex;
  position: relative;
}
.root:hover {
  text-decoration: none;
}
.root:global(.-clickable) {
  cursor: pointer;
}
.root:global(.uui-disabled) {
  cursor: default;
}
.root:global(.uui-button-box) {
  background-color: transparent;
  color: var(--uui-link_btn-text);
  fill: var(--uui-link_btn-text);
  min-width: auto;
}
.root:global(.uui-button-box):global(.-clickable:not(.uui-disabled)):hover {
  color: var(--uui-link_btn-text-hover);
  fill: var(--uui-link_btn-text-hover);
}
.root:global(.uui-button-box):global(.-clickable:not(.uui-disabled)):active {
  color: var(--uui-link_btn-text-active);
  fill: var(--uui-link_btn-text-active);
}
.root:global(.uui-button-box):global(.-clickable:not(.uui-disabled)):focus {
  box-shadow: none;
}
.root:global(.uui-button-box):global(.-clickable:not(.uui-disabled)):focus-visible {
  border-radius: 2px;
  box-shadow: inset 0 -2px 0 0 var(--uui-outline-focus), inset 0 2px 0 0 var(--uui-outline-focus), 2px 0 0 0 var(--uui-outline-focus), -2px 0 0 0 var(--uui-outline-focus);
}
.root:global(.uui-button-box):global(.uui-disabled) {
  color: var(--uui-link_btn-text-disabled);
  fill: var(--uui-link_btn-text-disabled);
  background-color: transparent;
}
.root:global(.uui-button-box):global(.uui-disabled):hover {
  background-color: transparent;
}
.root :global(.uui-caption) {
  font-family: var(--uui-link-button-font);
  font-weight: var(--uui-link-button-font-weight, normal);
}
.root:global.uui-size-18 {
  min-width: 18px;
}
.root:global.uui-size-18:global(.uui-has-left-icon) :global(.uui-caption) {
  padding-left: 6px;
}
.root:global.uui-size-18:global(.uui-has-right-icon) :global(.uui-caption) {
  padding-right: 6px;
}
.root:global.uui-size-18:global(.uui-button-box) {
  min-height: 18px;
  border-width: 0;
}
.root:global.uui-size-18 :global(.uui-caption) {
  line-height: 12px;
  font-size: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.root:global.uui-size-18 :global(.uui-icon) svg {
  height: 12px;
  width: inherit;
}
.root:global.uui-size-24 {
  min-width: 24px;
}
.root:global.uui-size-24:global(.uui-has-left-icon) :global(.uui-caption) {
  padding-left: 6px;
}
.root:global.uui-size-24:global(.uui-has-right-icon) :global(.uui-caption) {
  padding-right: 6px;
}
.root:global.uui-size-24:global(.uui-button-box) {
  min-height: 24px;
  border-width: 0;
}
.root:global.uui-size-24 :global(.uui-caption) {
  line-height: 18px;
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.root:global.uui-size-24 :global(.uui-icon) svg {
  height: 12px;
  width: inherit;
}
.root:global.uui-size-30 {
  min-width: 30px;
}
.root:global.uui-size-30:global(.uui-has-left-icon) :global(.uui-caption) {
  padding-left: 6px;
}
.root:global.uui-size-30:global(.uui-has-right-icon) :global(.uui-caption) {
  padding-right: 6px;
}
.root:global.uui-size-30:global(.uui-button-box) {
  min-height: 30px;
  border-width: 0;
}
.root:global.uui-size-30 :global(.uui-caption) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.root:global.uui-size-30 :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.root:global.uui-size-36 {
  min-width: 36px;
}
.root:global.uui-size-36:global(.uui-has-left-icon) :global(.uui-caption) {
  padding-left: 6px;
}
.root:global.uui-size-36:global(.uui-has-right-icon) :global(.uui-caption) {
  padding-right: 6px;
}
.root:global.uui-size-36:global(.uui-button-box) {
  min-height: 36px;
  border-width: 0;
}
.root:global.uui-size-36 :global(.uui-caption) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 9px;
  padding-bottom: 9px;
}
.root:global.uui-size-36 :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.root:global.uui-size-42 {
  min-width: 42px;
}
.root:global.uui-size-42:global(.uui-has-left-icon) :global(.uui-caption) {
  padding-left: 6px;
}
.root:global.uui-size-42:global(.uui-has-right-icon) :global(.uui-caption) {
  padding-right: 6px;
}
.root:global.uui-size-42:global(.uui-button-box) {
  min-height: 42px;
  border-width: 0;
}
.root:global.uui-size-42 :global(.uui-caption) {
  line-height: 24px;
  font-size: 16px;
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 16px;
}
.root:global.uui-size-42 :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.root:global.uui-size-48 {
  min-width: 48px;
}
.root:global.uui-size-48:global(.uui-has-left-icon) :global(.uui-caption) {
  padding-left: 6px;
}
.root:global.uui-size-48:global(.uui-has-right-icon) :global(.uui-caption) {
  padding-right: 6px;
}
.root:global.uui-size-48:global(.uui-button-box) {
  min-height: 48px;
  border-width: 0;
}
.root:global.uui-size-48 :global(.uui-caption) {
  line-height: 24px;
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 16px;
}
.root:global.uui-size-48 :global(.uui-icon) svg {
  height: 24px;
  width: inherit;
}