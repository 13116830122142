.root > :nth-child(n) {
  border-radius: 0;
}
.root > :first-child {
  border-top-left-radius: var(--uui-border-radius);
  border-bottom-left-radius: var(--uui-border-radius);
}
.root > :last-child {
  border-top-right-radius: var(--uui-border-radius);
  border-bottom-right-radius: var(--uui-border-radius);
}