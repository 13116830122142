.uui-typography {
  font-family: var(--uui-font);
  color: var(--uui-text-primary);
}
.uui-typography .hero-header, .uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin: 0;
}
.uui-typography .hero-header {
  font-family: var(--uui-font);
  font-weight: 500;
  margin: 30px 0;
  font-size: 66px;
  line-height: 72px;
}
.uui-typography .promo-header, .uui-typography h1, .uui-typography h2, .uui-typography h3 {
  font-family: var(--uui-font);
  font-weight: 700;
}
.uui-typography h4, .uui-typography h5, .uui-typography h6 {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.uui-typography h1 {
  font-size: 42px;
}
.uui-typography h2 {
  font-size: 30px;
  letter-spacing: 0.1px;
}
.uui-typography h2 .promo-header {
  letter-spacing: 2px;
}
.uui-typography h3 {
  font-size: 24px;
}
.uui-typography h4 {
  font-size: 18px;
  letter-spacing: 0.1px;
}
.uui-typography h5 {
  font-size: 16px;
}
.uui-typography h6 {
  font-size: 14px;
}
.uui-typography b, .uui-typography strong {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography i, .uui-typography em {
  font-family: var(--uui-font);
  font-weight: 400;
  font-style: italic;
}
.uui-typography a {
  display: inline-flex;
  align-items: center;
  color: var(--uui-link);
  text-decoration: underline;
}
.uui-typography a:visited {
  color: var(--uui-link-visited);
}
.uui-typography a:visited:hover {
  color: var(--uui-link-visited-hover);
}
.uui-typography a:hover {
  color: var(--uui-link-hover);
}
.uui-typography code {
  padding: 0.125em 0.25em;
  color: var(--uui-text-primary);
  background-color: var(--uui-neutral-30);
  font-family: var(--uui-font-mono, monospace);
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography ol, .uui-typography ul {
  padding: 0;
}
.uui-typography li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.uui-typography pre {
  padding: 1.25em;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography img {
  max-width: 100%;
}
.uui-typography figure {
  margin: 0;
}
.uui-typography figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.uui-typography p {
  margin: 0.5em 0;
  line-height: 1.5;
}
.uui-typography .uui-critical {
  color: var(--uui-text-critical);
}
.uui-typography .uui-info {
  color: var(--uui-text-info);
}
.uui-typography .uui-success {
  color: var(--uui-text-success);
}
.uui-typography .uui-warning {
  color: var(--uui-text-warning);
}
.uui-typography .uui-highlight {
  background-color: var(--uui-warning-10);
}

.uui-typography-size-12 {
  font-size: 12px;
}

.uui-typography-size-14 {
  font-size: 14px;
}

.uui-typography-size-16 {
  font-size: 16px;
}

.root {
  --uui-labeled_input: var(--uui-text-primary);
  --uui-labeled_input-disabled: var(--uui-text-disabled);
  --uui-labeled_input-error: var(--uui-error-50);
  --uui-labeled_input-fill: var(--uui-icon);
  --uui-labeled_input-info-fill: var(--uui-icon);
  --uui-labeled_input-optional-text: var(--uui-text-tertiary);
  --uui-labeled_input-asterisk: var(--uui-error-50);
  --uui-labeled_input-char-counter: var(--uui-text-tertiary);
  --uui-labeled_input-footnote: var(--uui-text-tertiary);
  --uui-labeled_input-sidenote: var(--uui-text-tertiary);
  --uui-labeled_input-font: var(--uui-font);
  --uui-labeled_input-font-weight: 600;
  --uui-labeled_input-optional-font: var(--uui-font);
  --uui-labeled_input-optional-font-weight: 400;
  --uui-labeled_input-optional-font-style: italic;
  --uui-labeled_input-invalid-msg-font: var(--uui-font);
  --uui-labeled_input-invalid-msg-font-weight: 400;
  --uui-labeled_input-char-counter-font: var(--uui-font);
  --uui-labeled_input-char-counter-font-weight: 400;
  --uui-labeled_input-footnote-font: var(--uui-font);
  --uui-labeled_input-footnote-font-weight: 400;
  --uui-labeled_input-sidenote-font: var(--uui-font);
  --uui-labeled_input-sidenote-font-weight: 400;
  width: 100%;
}
.root :global(.uui-label) {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--uui-labeled_input-font);
  font-weight: var(--uui-labeled_input-font-weight);
  color: var(--uui-labeled_input);
  padding: 6px 0;
}
.root :global(.uui-label) :global(.uui-labeled-input-info-icon) {
  flex-shrink: 0;
  margin-left: 6px;
  fill: var(--uui-labeled_input-info-fill);
}
.root :global(.uui-label) :global(.uui-labeled-input-asterisk) {
  color: var(--uui-labeled_input-asterisk);
}
.root :global(.uui-label) :global(.uui-labeled-input-optional) {
  font-family: var(--uui-labeled_input-optional-font);
  font-weight: var(--uui-labeled_input-optional-font-weight);
  font-style: var(--uui-labeled_input-optional-font-style);
  font-size: 12px;
  line-height: 18px;
  color: var(--uui-labeled_input-optional-text);
  margin-left: 6px;
}
.root :global(.uui-label-left) :global(.uui-label) {
  margin-right: 5px;
}
.root :global(.uui-invalid-message) {
  line-height: 18px;
  font-size: 12px;
  color: var(--uui-labeled_input-error);
  font-family: var(--uui-labeled_input-invalid-msg-font);
  font-weight: var(--uui-labeled_input-invalid-msg-font-weight);
  margin-top: 3px;
}
.root :global(.uui-labeled-input-char-counter) {
  flex-grow: 1;
  text-align: right;
  line-height: 18px;
  font-size: 12px;
  color: var(--uui-labeled_input-char-counter);
  font-family: var(--uui-labeled_input-char-counter-font);
  font-weight: var(--uui-labeled_input-char-counter-font-weight);
  margin-top: 3px;
}
.root :global(.uui-labeled-input-footnote) {
  line-height: 18px;
  font-size: 12px;
  color: var(--uui-labeled_input-footnote);
  font-family: var(--uui-labeled_input-footnote-font);
  font-weight: var(--uui-labeled_input-footnote-font-weight);
  margin-top: 3px;
}
.root :global(.uui-labeled-input-sidenote) {
  align-self: center;
  text-align: right;
  line-height: 18px;
  font-size: 12px;
  color: var(--uui-labeled_input-sidenote);
  font-family: var(--uui-labeled_input-sidenote-font);
  font-weight: var(--uui-labeled_input-sidenote-font-weight);
}
.root.size-24 :global(.uui-labeled-input-info-icon):global(.uui-icon) svg {
  height: 12px;
  width: 12px;
}
.root.size-24 :global(.uui-label) {
  font-size: 12px;
  line-height: 18px;
}
.root.size-30 :global(.uui-labeled-input-info-icon):global(.uui-icon) svg {
  height: 12px;
  width: 12px;
}
.root.size-30 :global(.uui-label) {
  font-size: 12px;
  line-height: 18px;
}
.root.size-36 :global(.uui-labeled-input-info-icon):global(.uui-icon) svg {
  height: 18px;
  width: 18px;
}
.root.size-36 :global(.uui-label) {
  font-size: 14px;
  line-height: 18px;
}
.root.size-42 :global(.uui-labeled-input-info-icon):global(.uui-icon) svg {
  height: 18px;
  width: 18px;
}
.root.size-42 :global(.uui-label) {
  font-size: 14px;
  line-height: 18px;
}
.root.size-48 :global(.uui-labeled-input-info-icon):global(.uui-icon) svg {
  height: 18px;
  width: 18px;
}
.root.size-48 :global(.uui-label) {
  font-size: 14px;
  line-height: 18px;
}