.root {
  --uui-count_indicator-bg: var(--uui-color-50);
  --uui-count_indicator-border: var(--uui-color-50);
  --uui-count_indicator-caption: var(--uui-color-contrast);
  --uui-count_indicator-font: var(--uui-font);
  --uui-count_indicator-font-weight: 600;
}
.root:global(.uui-count_indicator) {
  box-sizing: border-box;
  font-family: var(--uui-count_indicator-font);
  font-weight: var(--uui-count_indicator-font-weight);
  margin: auto;
  text-align: center;
  flex-shrink: 0;
  background-color: var(--uui-count_indicator-bg);
  border: 1px solid var(--uui-count_indicator-border);
  color: var(--uui-count_indicator-caption);
}
.root:global(.uui-count_indicator).size-24 {
  height: 24px;
  min-width: 24px;
  font-size: 14px;
  line-height: 22px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 12px;
}
.root:global(.uui-count_indicator).size-18 {
  height: 18px;
  min-width: 18px;
  font-size: 12px;
  line-height: 16px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 9px;
}
.root:global(.uui-count_indicator).size-12 {
  height: 12px;
  min-width: 12px;
  font-size: 8px;
  line-height: 10px;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 6px;
}