.guideContainer {
  width: 50vw !important;
  height: 450px !important;
}

.tabBtn {
  text-align: left;
}

.guideTab {
  align-items: start !important;
  flex-direction: column;
}

