.uui-typography {
  font-family: var(--uui-font);
  color: var(--uui-text-primary);
}
.uui-typography .hero-header, .uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin: 0;
}
.uui-typography .hero-header {
  font-family: var(--uui-font);
  font-weight: 500;
  margin: 30px 0;
  font-size: 66px;
  line-height: 72px;
}
.uui-typography .promo-header, .uui-typography h1, .uui-typography h2, .uui-typography h3 {
  font-family: var(--uui-font);
  font-weight: 700;
}
.uui-typography h4, .uui-typography h5, .uui-typography h6 {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.uui-typography h1 {
  font-size: 42px;
}
.uui-typography h2 {
  font-size: 30px;
  letter-spacing: 0.1px;
}
.uui-typography h2 .promo-header {
  letter-spacing: 2px;
}
.uui-typography h3 {
  font-size: 24px;
}
.uui-typography h4 {
  font-size: 18px;
  letter-spacing: 0.1px;
}
.uui-typography h5 {
  font-size: 16px;
}
.uui-typography h6 {
  font-size: 14px;
}
.uui-typography b, .uui-typography strong {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography i, .uui-typography em {
  font-family: var(--uui-font);
  font-weight: 400;
  font-style: italic;
}
.uui-typography a {
  display: inline-flex;
  align-items: center;
  color: var(--uui-link);
  text-decoration: underline;
}
.uui-typography a:visited {
  color: var(--uui-link-visited);
}
.uui-typography a:visited:hover {
  color: var(--uui-link-visited-hover);
}
.uui-typography a:hover {
  color: var(--uui-link-hover);
}
.uui-typography code {
  padding: 0.125em 0.25em;
  color: var(--uui-text-primary);
  background-color: var(--uui-neutral-30);
  font-family: var(--uui-font-mono, monospace);
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography ol, .uui-typography ul {
  padding: 0;
}
.uui-typography li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.uui-typography pre {
  padding: 1.25em;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography img {
  max-width: 100%;
}
.uui-typography figure {
  margin: 0;
}
.uui-typography figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.uui-typography p {
  margin: 0.5em 0;
  line-height: 1.5;
}
.uui-typography .uui-critical {
  color: var(--uui-text-critical);
}
.uui-typography .uui-info {
  color: var(--uui-text-info);
}
.uui-typography .uui-success {
  color: var(--uui-text-success);
}
.uui-typography .uui-warning {
  color: var(--uui-text-warning);
}
.uui-typography .uui-highlight {
  background-color: var(--uui-warning-10);
}

.uui-typography-size-12 {
  font-size: 12px;
}

.uui-typography-size-14 {
  font-size: 14px;
}

.uui-typography-size-16 {
  font-size: 16px;
}

.root {
  --uui-flex-row-bg: transparent;
  --uui-flex-row-border: 1px solid var(--uui-divider);
  box-sizing: content-box;
  background-color: var(--uui-flex-row-bg);
}
.root.border-top {
  border-top: var(--uui-flex-row-border);
}
.root.border-bottom {
  border-bottom: var(--uui-flex-row-border);
}
.root.top-shadow {
  box-shadow: inset 0 10px 5px -10px rgba(51, 51, 51, 0.2);
}
.root.size-24 {
  min-height: 24px;
}
.root.size-30 {
  min-height: 30px;
}
.root.size-36 {
  min-height: 36px;
}
.root.size-42 {
  min-height: 42px;
}
.root.size-48 {
  min-height: 48px;
}
.root.padding-6 {
  padding-left: 6px;
  padding-right: 6px;
}
.root.padding-12 {
  padding-left: 12px;
  padding-right: 12px;
}
.root.padding-18 {
  padding-left: 18px;
  padding-right: 18px;
}
.root.padding-24 {
  padding-left: 24px;
  padding-right: 24px;
}
.root.margin-24 {
  margin: 24px;
}
.root.margin-12 {
  margin: 12px;
}
.root.vPadding-12 {
  padding-top: 6px;
  padding-bottom: 6px;
}
.root.vPadding-18 {
  padding-top: 9px;
  padding-bottom: 9px;
}
.root.vPadding-24 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.root.vPadding-36 {
  padding-top: 18px;
  padding-bottom: 18px;
}
.root.vPadding-48 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.root.spacing-6 > *:not(:last-child) {
  margin-right: 3px;
}
.root.spacing-6 > *:not(:first-child) {
  margin-left: 3px;
}
.root.spacing-12 > *:not(:last-child) {
  margin-right: 6px;
}
.root.spacing-12 > *:not(:first-child) {
  margin-left: 6px;
}
.root.spacing-18 > *:not(:last-child) {
  margin-right: 9px;
}
.root.spacing-18 > *:not(:first-child) {
  margin-left: 9px;
}
.root.uui-surface-main {
  --uui-flex-row-bg: var(--uui-surface-main);
}