.uui-typography {
  font-family: var(--uui-font);
  color: var(--uui-text-primary);
}
.uui-typography .hero-header, .uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin: 0;
}
.uui-typography .hero-header {
  font-family: var(--uui-font);
  font-weight: 500;
  margin: 30px 0;
  font-size: 66px;
  line-height: 72px;
}
.uui-typography .promo-header, .uui-typography h1, .uui-typography h2, .uui-typography h3 {
  font-family: var(--uui-font);
  font-weight: 700;
}
.uui-typography h4, .uui-typography h5, .uui-typography h6 {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.uui-typography h1 {
  font-size: 42px;
}
.uui-typography h2 {
  font-size: 30px;
  letter-spacing: 0.1px;
}
.uui-typography h2 .promo-header {
  letter-spacing: 2px;
}
.uui-typography h3 {
  font-size: 24px;
}
.uui-typography h4 {
  font-size: 18px;
  letter-spacing: 0.1px;
}
.uui-typography h5 {
  font-size: 16px;
}
.uui-typography h6 {
  font-size: 14px;
}
.uui-typography b, .uui-typography strong {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography i, .uui-typography em {
  font-family: var(--uui-font);
  font-weight: 400;
  font-style: italic;
}
.uui-typography a {
  display: inline-flex;
  align-items: center;
  color: var(--uui-link);
  text-decoration: underline;
}
.uui-typography a:visited {
  color: var(--uui-link-visited);
}
.uui-typography a:visited:hover {
  color: var(--uui-link-visited-hover);
}
.uui-typography a:hover {
  color: var(--uui-link-hover);
}
.uui-typography code {
  padding: 0.125em 0.25em;
  color: var(--uui-text-primary);
  background-color: var(--uui-neutral-30);
  font-family: var(--uui-font-mono, monospace);
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography ol, .uui-typography ul {
  padding: 0;
}
.uui-typography li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.uui-typography pre {
  padding: 1.25em;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography img {
  max-width: 100%;
}
.uui-typography figure {
  margin: 0;
}
.uui-typography figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.uui-typography p {
  margin: 0.5em 0;
  line-height: 1.5;
}
.uui-typography .uui-critical {
  color: var(--uui-text-critical);
}
.uui-typography .uui-info {
  color: var(--uui-text-info);
}
.uui-typography .uui-success {
  color: var(--uui-text-success);
}
.uui-typography .uui-warning {
  color: var(--uui-text-warning);
}
.uui-typography .uui-highlight {
  background-color: var(--uui-warning-10);
}

.uui-typography-size-12 {
  font-size: 12px;
}

.uui-typography-size-14 {
  font-size: 14px;
}

.uui-typography-size-16 {
  font-size: 16px;
}

.root {
  --uui-badge-bg: var(--uui-color-50);
  --uui-badge-bg-hover: var(--uui-color-60);
  --uui-badge-border: var(--uui-color-50);
  --uui-badge-border-hover: var(--uui-color-60);
  --uui-badge-caption: var(--uui-color-contrast);
  --uui-badge-caption-hover: var(--uui-color-contrast);
  --uui-badge-font: var(--uui-font);
  --uui-badge-font-weight: 400;
  text-decoration: none;
  outline: none;
  border: 0;
  background: none;
  box-sizing: border-box;
  appearance: none;
  align-items: unset;
  padding: unset;
  margin: unset;
  justify-content: unset;
  overflow: hidden;
  display: flex;
  position: relative;
  background-color: var(--uui-badge-bg);
  color: var(--uui-badge-caption);
  fill: var(--uui-badge-caption);
  border-color: var(--uui-badge-border);
  justify-content: center;
}
.root:hover {
  text-decoration: none;
}
.root:global(.-clickable) {
  cursor: pointer;
}
.root:global(.uui-disabled) {
  cursor: default;
}
.root:global(.-clickable):hover {
  background-color: var(--uui-badge-bg-hover);
  border-color: var(--uui-badge-border-hover);
  color: var(--uui-badge-caption-hover);
  fill: var(--uui-badge-caption-hover);
  cursor: pointer;
}
.root :global(.uui-caption) {
  font-family: var(--uui-badge-font);
  font-weight: var(--uui-badge-font-weight);
}
.root:global(.uui-button-box) {
  border-width: 1px;
  border-style: solid;
}
.root:global(.-clickable):focus-visible {
  outline: 2px solid var(--uui-outline-focus);
  outline-offset: 2px;
}
.root :global(.uui-count_indicator) {
  margin-right: 3px;
}
.root:global(.uui-indicator)::before {
  position: absolute;
  content: "";
  border-radius: 50%;
  background-color: var(--uui-badge-caption);
}
.root.size-18 {
  min-height: 18px;
  min-width: 18px;
  border-radius: 9px;
  padding-left: 2px;
  padding-right: 2px;
}
.root.size-18 :global(.uui-icon):first-child {
  padding-left: 3px;
}
.root.size-18 :global(.uui-caption) {
  padding-left: 3px;
  padding-right: 3px;
}
.root.size-18:global(.uui-indicator) :global(.uui-caption) {
  margin-left: 9px;
}
.root.size-18:global(.uui-indicator)::before {
  width: 6px;
  height: 6px;
}
.root.size-18 :global(.uui-icon) svg {
  height: 12px;
  width: inherit;
}
.root.size-18:global(.uui-indicator)::before {
  top: 5px;
  left: 5px;
}
.root.size-18 :global(.uui-caption) {
  padding-top: 2px;
  padding-bottom: 2px;
  line-height: 12px;
  font-size: 12px;
}
.root.size-24 {
  min-height: 24px;
  min-width: 24px;
  border-radius: 12px;
  padding-left: 2px;
  padding-right: 2px;
}
.root.size-24 :global(.uui-icon):first-child {
  padding-left: 3px;
}
.root.size-24 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.root.size-24:global(.uui-indicator) :global(.uui-caption) {
  margin-left: 14px;
}
.root.size-24:global(.uui-indicator)::before {
  width: 8px;
  height: 8px;
}
.root.size-24 :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.root.size-24:global(.uui-indicator)::before {
  top: 7px;
  left: 9px;
}
.root.size-24 :global(.uui-caption) {
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 12px;
  font-size: 12px;
}
.root.size-30 {
  min-height: 30px;
  min-width: 30px;
  border-radius: 15px;
  padding-left: 5px;
  padding-right: 5px;
}
.root.size-30 :global(.uui-icon):first-child {
  padding-left: 3px;
}
.root.size-30 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.root.size-30:global(.uui-indicator) :global(.uui-caption) {
  margin-left: 14px;
}
.root.size-30:global(.uui-indicator)::before {
  width: 8px;
  height: 8px;
}
.root.size-30 :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.root.size-30:global(.uui-indicator)::before {
  top: 10px;
  left: 11px;
}
.root.size-30 :global(.uui-caption) {
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 18px;
  font-size: 14px;
}
.root.size-36 {
  min-height: 36px;
  min-width: 36px;
  border-radius: 18px;
  padding-left: 8px;
  padding-right: 8px;
}
.root.size-36 :global(.uui-icon):first-child {
  padding-left: 3px;
}
.root.size-36 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.root.size-36:global(.uui-indicator) :global(.uui-caption) {
  margin-left: 14px;
}
.root.size-36:global(.uui-indicator)::before {
  width: 8px;
  height: 8px;
}
.root.size-36 :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.root.size-36:global(.uui-indicator)::before {
  top: 13px;
  left: 15px;
}
.root.size-36 :global(.uui-caption) {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 18px;
  font-size: 14px;
}
.root.size-42 {
  min-height: 42px;
  min-width: 42px;
  border-radius: 21px;
  padding-left: 11px;
  padding-right: 11px;
}
.root.size-42 :global(.uui-icon):first-child {
  padding-left: 3px;
}
.root.size-42 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.root.size-42:global(.uui-indicator) :global(.uui-caption) {
  margin-left: 16px;
}
.root.size-42:global(.uui-indicator)::before {
  width: 10px;
  height: 10px;
}
.root.size-42 :global(.uui-icon) svg {
  height: 24px;
  width: inherit;
}
.root.size-42 :global(.uui-caption) {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 24px;
  font-size: 16px;
}
.root.size-42:global(.uui-indicator)::before {
  top: 15px;
  left: 17px;
}
.root.size-48 {
  min-height: 48px;
  min-width: 48px;
  border-radius: 24px;
  padding-left: 11px;
  padding-right: 11px;
}
.root.size-48 :global(.uui-icon):first-child {
  padding-left: 3px;
}
.root.size-48 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.root.size-48:global(.uui-indicator) :global(.uui-caption) {
  margin-left: 18px;
}
.root.size-48:global(.uui-indicator)::before {
  width: 12px;
  height: 12px;
}
.root.size-48 :global(.uui-icon) svg {
  height: 24px;
  width: inherit;
}
.root.size-48:global(.uui-indicator)::before {
  top: 17px;
  left: 17px;
}
.root.size-48 :global(.uui-caption) {
  padding-top: 11px;
  padding-bottom: 11px;
  line-height: 24px;
  font-size: 16px;
}
.root:global(.uui-fill-solid) {
  --uui-badge-bg: var(--uui-color-50);
  --uui-badge-bg-hover: var(--uui-color-60);
  --uui-badge-border: var(--uui-color-50);
  --uui-badge-border-hover: var(--uui-color-60);
  --uui-badge-caption: var(--uui-color-contrast);
  --uui-badge-caption-hover: var(--uui-color-contrast);
}
.root:global(.uui-fill-solid) :global(.uui-count_indicator) {
  --uui-count_indicator-bg: color-mix(in srgb, var(--uui-badge-caption) 8%, transparent);
  --uui-count_indicator-border: color-mix(in srgb, var(--uui-badge-caption) 40%, transparent);
}
.root:global(.uui-fill-outline) {
  --uui-badge-bg: var(--uui-color-5);
  --uui-badge-bg-hover: var(--uui-color-10);
  --uui-badge-border: var(--uui-color-20);
  --uui-badge-border-hover: var(--uui-color-20);
  --uui-badge-caption: var(--uui-color-70);
  --uui-badge-caption-hover: var(--uui-color-70);
}
.root:global(.uui-fill-outline) :global(.uui-count_indicator) {
  --uui-count_indicator-bg: var(--uui-neutral-0);
  --uui-count_indicator-border: var(--uui-neutral-40);
  --uui-count_indicator-caption: var(--uui-neutral-70);
}