.textInputRow {
  display: grid !important;
  grid-auto-flow: column;
}

.customInput {
  display: flex;
  justify-content: center;
  align-items: center;
}

.customInputDestination {
  grid-column: 2;
}

.textInput {
  width: 175px !important;
}

.pickerNotFound {
  width: 100% !important;
}

.start {
  justify-self: start;
}

.end {
  justify-self: end;
}

.dropdownBody {
  width: 175px !important;
  max-width: 175px !important;
  min-width: 0 !important;
}

.courierContainer {
  width: 450px !important;
}

.swapBtn {
  transform: rotate(90deg);
  margin-top: 24px !important;
}
