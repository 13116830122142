.root {
  --uui-time_picker-text: var(--uui-control-text);
  --uui-time_picker-icon-clickable: var(--uui-icon);
  --uui-time_picker-icon-clickable-hover: var(--uui-icon-hover);
}
.root:global(.uui-timepicker-container) {
  display: flex;
  padding: 15px 12px;
  justify-content: center;
}
.root:global(.uui-timepicker-container) :global(.uui-timepicker-input):global(.uui-input-box) {
  margin: 6px 0;
  width: 36px;
  height: 36px;
}
.root:global(.uui-timepicker-container) :global(.uui-timepicker-input) > input {
  text-align: center;
  font-size: 24px;
  line-height: 24px;
  color: var(--uui-time_picker-text);
  font-family: var(--uui-font);
  font-weight: 400;
}
.root:global(.uui-timepicker-container) :global(.uui-timepicker-input) > input::placeholder, .root:global(.uui-timepicker-container) :global(.uui-timepicker-input) > input::-webkit-input-placeholder {
  color: var(--uui-time_picker-text);
  opacity: 1; /* for Firefox */
}
.root:global(.uui-timepicker-container) :global(.uui-timepicker-item) {
  position: relative;
  padding: 9px 24px;
}
.root:global(.uui-timepicker-container) :global(.uui-timepicker-item:first-child)::after {
  content: ":";
  position: absolute;
  top: 35px;
  left: 81px;
  color: var(--uui-text-tertiary);
  font-size: 24px;
  line-height: 30px;
}
.root:global(.uui-timepicker-container) :global(.uui-timepicker-icon-up), .root:global(.uui-timepicker-container) :global(.uui-timepicker-icon-down) {
  align-items: center;
  fill: var(--uui-time_picker-icon-clickable);
}
.root:global(.uui-timepicker-container) :global(.uui-timepicker-icon-up):hover, .root:global(.uui-timepicker-container) :global(.uui-timepicker-icon-down):hover {
  fill: var(--uui-time_picker-icon-clickable-hover);
}
.root:global(.uui-timepicker-container) :global(.uui-timepicker-icon-up) {
  transform: rotate(180deg);
}

.timepicker-input {
  width: 100%;
}