.root {
  --uui-scroll_bars-bg: var(--uui-neutral-40);
  --uui-scroll_bars-bg-active: var(--uui-neutral-60);
}

:global(.uui-shadow-top)::before {
  background: var(--scroll-bars-shadow);
}

:global(.uui-shadow-bottom)::after {
  background: var(--scroll-bars-shadow);
}

:global(.uui-thumb-vertical), :global(.uui-thumb-horizontal) {
  background: var(--uui-scroll_bars-bg);
  border-radius: 1.5px;
}
:global(.uui-thumb-vertical):hover, :global(.uui-thumb-horizontal):hover {
  border-radius: 3px;
}
:global(.uui-thumb-vertical):active, :global(.uui-thumb-horizontal):active {
  border-radius: 3px;
  background: var(--uui-scroll_bars-bg-active);
}

:global(.uui-thumb-vertical) {
  width: 3px;
  margin-left: 3px;
}
:global(.uui-thumb-vertical):hover {
  width: 6px;
  margin-left: 0;
}
:global(.uui-thumb-vertical):active {
  width: 6px;
  margin-left: 0;
}

:global(.uui-thumb-horizontal) {
  height: 3px;
  margin-top: 3px;
}
:global(.uui-thumb-horizontal):hover {
  height: 6px;
  margin-top: 0;
}
:global(.uui-thumb-horizontal):active {
  height: 6px;
  margin-top: 0;
}